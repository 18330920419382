import { forwardRef } from 'react';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import { Label } from '../Label/Label';

export const TextArea = forwardRef((props, ref) => {
  const { id, placeholder, onChange, label, error } = props;
  return (
    <div>
      {label && <Label id={id} label={label} />}
      <textarea
        name={id}
        id={id}
        rows="8"
        ref={ref}
        className="relative block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
        placeholder={placeholder}
        onChange={onChange}
      />
      {error && <ErrorMessage msg={error} />}
    </div>
  );
});

TextArea.displayName = 'TextArea';
