import { Heading } from 'components/Common/Header/Heading';
import { Container } from 'components/Container/Container';
import { ProfileDetails } from './components/ProfileDetails';
import { useStore } from 'hooks';
import { Avatar } from 'components/Avatar/Avatar';
import { AuditTrail } from 'components/AuditTrail/AuditTrail';
import { auditService } from 'services';
import { useParams } from 'react-router-dom';

export const Profile = () => {
  const { user } = useStore();
  const { id } = useParams();
  const name = `${user?.firstName} ${user?.lastName}`;

  return (
    <>
      <div className="py-6 px-4 lg:px-8 grid grid-cols-12">
        <div className="lg:col-span-10 col-span-12">
          <Container>
            <div className="lg:flex md:block justify-between mt-2">
              <Heading>
                <h1>Profile Information</h1>
              </Heading>
            </div>
            <div className="flex items-center gap-3 uppercase">
              <Avatar textSize="20" size={64} name={name} />
              <p className="text-lg font-medium">{name}</p>
            </div>
            <ProfileDetails data={user}></ProfileDetails>
          </Container>
        </div>
        <div className="lg:col-span-10 col-span-12 mt-8">
          <AuditTrail
            queryFunc={auditService.getUserAuditTrails}
            otherParams={{ userId: id ?? user?._id }}
            queryKey="getUserAuditTrails"
          />
        </div>
      </div>
    </>
  );
};
