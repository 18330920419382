import http from 'plugins/axios';
import { notification } from 'utils';
import { PER_PAGE } from 'constants/pagination';

class TransactionService {
  async getBankList() {
    try {
      const { data } = await http.get('/api/bank-list');
      return data;
    } catch (error) {
      throw new Error(error);
    }
  }

  async resolveBankAccount(payload) {
    try {
      const { data } = await http.post('/api/resolve-account', payload);
      return data;
    } catch (error) {
      throw new Error(error);
    }
  }

  async initiateTransaction(payload) {
    try {
      const { data } = await http.post('/api/requests/initiate', payload);
      notification(data.message ?? 'Transaction initiated successfully');
      return data.data;
    } catch (error) {
      notification(error.response?.data?.message, 'error');
      throw new Error(error);
    }
  }

  async getAssignedRequests(params) {
    try {
      const { data } = await http.get('/api/requests/assigned', {
        params: { ...params, perPage: PER_PAGE }
      });
      return data;
    } catch (error) {
      throw new Error(error);
    }
  }

  async getAllInitiatedRequests(params) {
    try {
      const { data } = await http.get('/api/requests/initiator', {
        params: { ...params, perPage: PER_PAGE }
      });
      return data;
    } catch (error) {
      throw new Error(error);
    }
  }

  async getTransactionPerOrganization(params) {
    try {
      const { data } = await http.get('/api/requests/all', {
        params: { ...params, perPage: PER_PAGE }
      });
      return data;
    } catch (error) {
      throw new Error(error);
    }
  }

  async getTransactionById(id) {
    try {
      const { data } = await http.get(`/api/requests/${id}`);
      return data;
    } catch (error) {
      throw new Error(error);
    }
  }

  async authorizerAction({ id, reason, otp, status }) {
    try {
      const response = await http.put(`/api/requests/authoriser/${status}/${id}`, { reason, otp });
      notification(response.message ?? `Transaction ${status}d successfully`);
      return response.request;
    } catch (error) {
      notification(error.response?.data?.message, 'error');
      throw new Error(error);
    }
  }

  async verifierAction({ id, reason, otp, status }) {
    try {
      const response = await http.put(`/api/requests/verifier/${status}/${id}`, { reason, otp });
      notification(response.message ?? `Transaction ${status}d successfully`);
      return response.request;
    } catch (error) {
      notification(error.response?.data?.message, 'error');
      throw new Error(error);
    }
  }

  async bulkUploadTransaction({ file, payerAccountNumber }) {
    const formData = new FormData();
    formData.append('files', file);
    formData.append('originatorAccountNumber', payerAccountNumber);
    try {
      const data = await http.post('/api/requests/verify_batchUpload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      notification(data?.message ?? 'Transaction initiated successfully');
      return data;
    } catch (error) {
      notification(error?.response?.data?.message, 'error');
      throw error.response;
    }
  }

  async bulkAuthorizeTransaction(payload) {
    try {
      const response = await http.post('/api/requests/bulk/authoriser/approve', {
        ...payload
      });
      notification(response.message ?? 'Transaction authorized successfully');
      return response.request;
    } catch (error) {
      notification(error.response?.data?.message, 'error');
      throw new Error(error);
    }
  }

  async bulkVerifyTransaction(payload) {
    try {
      const response = await http.post('/api/requests/bulk/verifier/approve', payload);
      notification(response.message ?? 'Transaction authorized successfully');
      return response.request;
    } catch (error) {
      notification(error.response?.data?.message, 'error');
      throw new Error(error);
    }
  }
}

export const transactionService = new TransactionService();
