const List = ({ children }) => {
  return <div className="mt-4  border rounded">{children}</div>;
};

const ListContainer = ({ children }) => {
  return (
    <div className="w-full justify-between mt-4 flex flex-col lg:flex-row p-4 border-b">
      {children}
    </div>
  );
};

const ListItem = ({ icon, title, children, left = false, size = 'text-sm ' }) => {
  return (
    <div>
      <div className="flex items-center relative">
        <p
          className={
            left ? 'text-sm font-medium text-gray-500' : 'text-gray-500 font-medium text-sm mr-2'
          }
        >
          {icon}
        </p>
        <p
          className={
            left ? 'font-medium text-md text-gray-500' : `text-gray-500 ${size} font-medium`
          }
        >
          {title}
        </p>
      </div>
      <div className="text-sm mt-3 font-normal">{children}</div>
    </div>
  );
};

List.Container = ListContainer;
List.Item = ListItem;

export { List };
