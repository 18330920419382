import { EmptyState } from 'components/EmptyState/EmptyState';
import { TransactionHistoryTable } from 'pages/TransactionHistory/components/TransactionHistoryTable';
import ContentLoader from 'react-content-loader';

const RenderData = (data) => {
  if (!data?.data?.IsSuccessful || data?.data?.Message.length === 0) {
    return (
      <EmptyState
        title="No transactions"
        description="You are yet to make a transaction. Check back later."
      />
    );
  } else {
    return <TransactionHistoryTable transactions={data?.data?.Message?.data ?? []} />;
  }
};

export const TransactionHistory = ({ transactions }) => {
  return transactions.isFetching ? (
    <ContentLoader viewBox="0 0 380 70">
      <rect x="0" y="0" rx="5" ry="5" width="380" height="70" />
    </ContentLoader>
  ) : (
    <RenderData data={transactions.data} />
  );
};
