import { Auth } from 'components/Layout';
import { CreateSecurityQuestionForm } from './CreateSecurityQuestionForm';
import { useLocation } from 'react-router-dom';
import { Container } from 'components/Container/Container';

export const CreateSecurityQuestion = () => {
  const { state } = useLocation();
  const email = state?.email ?? null;
  const showPasswordForm = state?.withPassword;
  const title = showPasswordForm
    ? 'Create password and security questions'
    : 'Create security questions';
  return (
    <Auth title={title} pos={'center'} center=" text-center justify-center flex">
      <Container>
        <CreateSecurityQuestionForm showPasswordForm={showPasswordForm ?? false} email={email} />
      </Container>
    </Auth>
  );
};
