import { List } from 'components/List/List';
import {
  EnvelopeIcon,
  PhoneIcon,
  UserCircleIcon,
  InformationCircleIcon,
  UserGroupIcon
} from '@heroicons/react/20/solid';
import { Badge } from 'components/Badge/Badge';
export const ProfileDetails = ({ data }) => {
  return (
    <div className="pt-3">
      <List>
        <List.Container>
          <List.Item icon={<UserCircleIcon width="20px" />} title="Name" />
          <List.Item title={`${data?.firstName ?? ''} ${data?.lastName ?? ''}`} left={true} />
        </List.Container>
        <List.Container>
          <List.Item icon={<EnvelopeIcon width="20px" />} title="Email" />
          <List.Item title={data?.email} left={true} />
        </List.Container>
        <List.Container>
          <List.Item icon={<PhoneIcon width="20px" />} title="Phone number" />
          <List.Item title={data?.phone} left={true} />
        </List.Container>
        <List.Container>
          <List.Item icon={<UserGroupIcon width="20px" />} title="Role" />
          <List.Item title={data?.role} left={true} />
        </List.Container>
        <List.Container>
          <List.Item icon={<InformationCircleIcon width="20px" />} title="Privilege" />
          <List.Item
            title={data?.privileges.map((privilege) => {
              return (
                <p key={privilege.name} className="mb-2">
                  <Badge>{privilege.name ?? ''}</Badge>
                </p>
              );
            })}
          />
        </List.Container>
      </List>
    </div>
  );
};
