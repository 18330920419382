import { useQuery } from '@tanstack/react-query';
import { Link, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import ContentLoader from 'react-content-loader';
import { Button } from 'components/Button/Button';
import { Container } from 'components/Container/Container';
import { mandateService } from 'services';
import { MandateRuleTable } from './MandateRuleTable';
import { useModal } from 'hooks/useModal';
import { MandateDetails } from './MandateRuleDetails/MandateRuleDetails';
import { EmptyState } from 'components/EmptyState/EmptyState';
import { usePrivilege } from 'hooks';
import SearchFilter from 'components/Form/SearchFilter/SearchFilter';
import Pagination from 'components/Pagination/Pagination';
import { useTableSerialNumber } from 'hooks/useTableSerialNumber';

export const MandateRule = () => {
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState(undefined);
  const { hasPrivilege } = usePrivilege();
  const [currentMandate, setCurrentMandate] = useState(null);
  const { showModal, Modal } = useModal();
  const [page, setPage] = useState(1);

  const { data, isLoading, isFetching, refetch } = useQuery({
    queryKey: ['mandateRule', { page }],
    queryFn: () => mandateService.getAll({ page, name: searchValue })
  });
  const initialSerialNumber = useTableSerialNumber(page);

  const setMandate = (mandate) => {
    setCurrentMandate(mandate);
    showModal();
  };
  const hasAdminPrivilege = !!hasPrivilege(['admin']);

  const RenderData = () => {
    if (data?.mandates?.length === 0) {
      return (
        <EmptyState
          title="No mandate rule found"
          description="You have not created any mandate rule yet."
          showWAction={hasAdminPrivilege}
          action={{
            label: 'Create mandate rule',
            onClick: () => navigate('/settings/mandate-rule/create')
          }}
        />
      );
    } else {
      return (
        <MandateRuleTable
          mandates={data?.mandates ?? []}
          isLoading={isLoading}
          setMandate={setMandate}
          initialSerialNumber={initialSerialNumber}
        />
      );
    }
  };

  return (
    <>
      <Container>
        <div className="p-3">
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
              <h1 className="text-xl font-semibold text-gray-900">Mandate Rules</h1>
              <p className="text-sm text-gray-700 mt-2">
                List of mandate rules made on this account.
              </p>
            </div>
            {hasAdminPrivilege && (
              <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                <Link to="/settings/mandate-rule/create">
                  <Button variant="primary" type="button">
                    Create Mandate
                  </Button>
                </Link>
              </div>
            )}
          </div>

          <SearchFilter
            placeholder={'Search by type....'}
            value={searchValue}
            setValue={setSearchValue}
            onSearch={() => {
              page !== 1 ? setPage(1) : refetch();
            }}
          />

          {isLoading || isFetching ? (
            <div className="mt-5">
              <ContentLoader viewBox="0 0 380 70">
                <rect x="0" y="0" rx="5" ry="5" width="380" height="70" />
              </ContentLoader>
            </div>
          ) : (
            <>
              <RenderData />
              <Pagination
                totalItems={data?.meta?.total ?? 0}
                handlePageClick={setPage}
                itemsPerPage={data?.meta?.perPage}
                currentPage={page}
              />
            </>
          )}
        </div>
      </Container>
      {Modal({ children: <MandateDetails mandate={currentMandate} />, size: 'lg' })}
    </>
  );
};
