import React from 'react';
import { Link } from 'react-router-dom';
import logoImage from 'img/gmfb.png';
export const Logo = (prop) => {
  return (
    <div>
      <Link to="/dashboard">
        <img className={prop.className} src={logoImage} alt="Your Company" width="190px" />
      </Link>
    </div>
  );
};
