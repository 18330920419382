import { Avatar } from 'components/Avatar/Avatar';
import { Link, useNavigate } from 'react-router-dom';
import { naira } from 'utils/currencyFormatter';
import { Dropdown } from 'flowbite-react';
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid';
import { useModal, usePrivilege } from 'hooks';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { mandateService } from 'services';
import { useState } from 'react';
import { SubHeading } from 'components/Common/Header/SubHeading';
import { Button } from 'components/Button/Button';

export const MandateRuleTable = ({ mandates, setMandate, initialSerialNumber }) => {
  const navigate = useNavigate();
  const { Modal, showModal } = useModal();
  const [id, setId] = useState(null);
  const queryClient = useQueryClient();
  const { hasPrivilege } = usePrivilege();
  const hasAdminPrivilege = !!hasPrivilege(['admin']);
  const deleteMandate = useMutation(
    (userid) => {
      mandateService.deleteMandate(userid);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('mandateRule');
        navigate('/settings/mandate-rule');
      }
    }
  );
  return (
    <div role="list" className="divide-y divide-gray-200">
      <div className="relative lg:overflow-visible overflow-x-auto  mt-6">
        <table className="w-full text-sm text-left text-gray-500">
          <thead className="text-xs bg-gray-100 uppercase border text-black">
            <tr>
              <th scope="col" className="p-3">
                S/N
              </th>
              <th scope="col" className="p-3">
                Name
              </th>

              <th scope="col" className="p-3">
                Min amount
              </th>
              <th scope="col" className="p-3">
                Max amount
              </th>
              <th scope="col" className="p-3">
                Authorizer
              </th>
              <th scope="col" className="p-3">
                <span className="sr-only">Edit</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {mandates.map((mandate, i) => (
              <tr key={mandate.name} className="border hover:bg-gray-50">
                <td className="whitespace-nowrap p-3 border capitalize">
                  {initialSerialNumber + i}
                </td>
                <td className="whitespace-nowrap p-3 border capitalize">{mandate.name}</td>
                <td className="whitespace-nowrap p-3 border capitalize">
                  {naira.format(mandate.minAmount)}
                </td>
                <td className="whitespace-nowrap p-3 border capitalize">
                  {naira.format(mandate.maxAmount)}
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                  <Avatar
                    name={`${mandate?.authoriser?.firstName} ${mandate?.authoriser?.lastName}`}
                  />
                </td>
                <td className="whitespace-nowrap p-3 border capitalize">
                  <Dropdown
                    label={<EllipsisVerticalIcon className="h-5 w-5 text-gray-600" />}
                    inline={true}
                    arrowIcon={false}
                    placement="left">
                    <Dropdown.Item>
                      <Link
                        href="#"
                        className="text-primary"
                        to={`/settings/mandate-rule/update/${mandate._id}`}>
                        Edit
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <a href="#" className="text-primary mr-3" onClick={() => setMandate(mandate)}>
                        View
                      </a>
                    </Dropdown.Item>
                    {hasAdminPrivilege ? (
                      <Dropdown.Item>
                        <span
                          className="text-primary mr-3"
                          onClick={() => {
                            showModal();
                            setId(mandate?._id);
                          }}>
                          Delete
                        </span>
                      </Dropdown.Item>
                    ) : null}
                  </Dropdown>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {Modal({
        children: (
          <div className="text-center">
            <SubHeading>Are you sure you want to delete mandate?</SubHeading>
            <p className="mt-2">Note deleting mandate is irreversible</p>
            <div className="flex justify-center gap-8 mt-3">
              <Button
                variant="danger"
                onClick={() => {
                  deleteMandate.mutate(id);
                }}>
                Delete
              </Button>
              <Button
                variant="outline"
                onClick={() => {
                  showModal();
                }}>
                Cancel
              </Button>
            </div>
          </div>
        ),
        showCloseIcon: true,
        size: 'md'
      })}
    </div>
  );
};
