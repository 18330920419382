import { BuildingOfficeIcon } from '@heroicons/react/24/outline';
import { useStore } from 'hooks';
import { accountService } from 'services';
import { BanknotesIcon, UserCircleIcon } from '@heroicons/react/20/solid';
import { useQuery } from '@tanstack/react-query';

export default function Header({ children, selected }) {
  const { user } = useStore();

  const { data } = useQuery({
    queryFn: () => accountService.getAccountInfo(user.organizationId.accountNumber[[0]]),
    queryKey: ['getAccountInfo', 'account-info'],
    enabled: !!user?.organizationId?.accountNumber
  });

  return (
    <div className="bg-white shadow">
      <div className="px-4 sm:px-6 -mt-2">
        <div className="py-6 flex lg:flex-row items-start lg:items-center  flex-col lg:justify-between lg:border-t lg:border-gray-200 px-5">
          <div className="min-w-0 flex-1">
            <div className="flex space-x-5">
              <div className="space-y-3">
                <h1 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:leading-9 capitalize">
                  Hello, {user?.firstName}
                </h1>
                <dl className="lg:flex block lg:space-x-6 space-x-0  lg:space-y-0">
                  <dd className="flex items-center text-sm font-medium capitalize text-gray-500">
                    <BuildingOfficeIcon
                      className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                      aria-hidden="true"
                    />
                    {data?.Message?.Branch ?? 'No Branch'}
                  </dd>
                  <dd className="flex items-center text-sm font-medium capitalize text-gray-500">
                    <UserCircleIcon
                      className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                      aria-hidden="true"
                    />
                    {data?.Message?.Name ?? 'No Name'}
                  </dd>
                  <dd className="flex items-center text-sm font-medium capitalize text-gray-500">
                    <BanknotesIcon
                      className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                      aria-hidden="true"
                    />
                    {data?.Message?.Product ?? 'No Product'}
                  </dd>
                </dl>
                <dd className="flex items-center text-sm font-medium capitalize text-gray-500">
                  <BanknotesIcon
                    className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                    aria-hidden="true"
                  />
                  {selected ?? 'No Account Number'}
                </dd>
              </div>
            </div>
          </div>
          <div className="mt-6 flex space-x-3  items-end">{children}</div>
        </div>
      </div>
    </div>
  );
}
