import http from 'plugins/axios';

class AnalyticsService {
  async getAnalysis(account, year) {
    try {
      const { data } = await http.get(`/api/requests/analysis/account/${account}/${year}`);
      return data;
    } catch (error) {
      // notification(error?.response?.data?.message ?? 'Something went wrong', 'error');
      throw new Error(error);
    }
  }
}

export const analyticsService = new AnalyticsService();
