import { useParams } from 'react-router-dom';
import { Auth } from 'components/Layout';
import { authService } from 'services';
import { CreateSecurityQuestionForm } from '../CreateSecurityQuestion/CreateSecurityQuestionForm';

export const VerifyAccount = () => {
  const { token } = useParams();

  return (
    <Auth
      title="Create password and security questions"
      pos="center"
      center="flex justify-center text-center"
      width="lg:w-[40%]"
      showBanner={false}>
      <div className="p-8 bg-white border rounded-xl">
        <CreateSecurityQuestionForm
          showPasswordForm
          service={authService.verifyAccount}
          extraPayload={{ token }}
        />
      </div>
    </Auth>
  );
};
