import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  LineElement,
  PointElement
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { SubHeading } from 'components/Common/Header/SubHeading';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Label } from 'components/Form/Label/Label';
import { naira } from 'utils/currencyFormatter';

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top'
    },
    title: {
      display: true,
      text: 'Disbursed Transactions'
    }
  }
};

ChartJS.register(CategoryScale, LinearScale, LineElement, Title, Tooltip, Legend, PointElement);

export const Charts = (props) => {
  const data = {
    labels: props.data.map((data) => data.month),
    datasets: [
      {
        label: '',
        data: props.data.map((data) => data.amount),
        backgroundColor: '#891c69'
      }
    ]
  };

  return (
    <>
      <div className="flex items-center flex-col lg:flex-row justify-between mt-5 mb-4">
        <div>
          <SubHeading>Financial statistics</SubHeading>
          <p className="text-3xl tracking-tight font-medium flex items-center mt-2">
            {naira.format(props.totalAmount)}
          </p>
        </div>
        <div className="w-[200px]">
          <Label label="Select Year" />
          <DatePicker
            selected={props.year}
            onChange={props.setYear}
            showYearPicker
            dateFormat="yyyy"
            className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm disabled:cursor-not-allowed disabled:opacity-70"
          />
        </div>
      </div>
      <Line options={options} data={data} />
    </>
  );
};
