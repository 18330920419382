import http from 'plugins/axios';
import { PER_PAGE } from 'constants/pagination';
import { notification } from 'utils';

const DEFAULT_MESSAGE = 'Something went wrong, please try again';
class MandateService {
  async create(payload) {
    try {
      const response = await http.post('/api/mandate/create', { ...payload });
      notification(response?.message ?? 'Mandate created successfully', 'success');
      return response.data;
    } catch (error) {
      notification(error.response.data.message ?? DEFAULT_MESSAGE, 'error');
      throw new Error(error);
    }
  }
  async update(payload, id) {
    try {
      const response = await http.put(`/api/mandate/update/${id}`, { ...payload });
      notification('Mandate updated successfully');
      return response;
    } catch (error) {
      notification(error?.response?.data?.message ?? DEFAULT_MESSAGE, 'error');
      throw new Error(error);
    }
  }
  async getAll(params) {
    try {
      const { data } = await http.get('/api/mandate/all', {
        params: { ...params, perPage: PER_PAGE }
      });
      return data;
    } catch (error) {
      throw new Error(error);
    }
  }
  async getOne(id) {
    try {
      const response = await http.get(`/api/mandate/${id}`);
      return response;
    } catch (error) {
      throw new Error(error);
    }
  }
  async deleteMandate(id) {
    try {
      const response = await http.delete(`/api/mandate/deleteMandate/${id}`);
      notification('mandate deleted successfully');
      return response;
    } catch (error) {
      throw new Error(error);
    }
  }
}

export const mandateService = new MandateService();
