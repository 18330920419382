import React from 'react';
import { Container } from 'components/Container/Container';
import { Heading } from 'components/Common/Header/Heading';
import { EditProfileForm } from './EditProfileForm';
import { userService } from 'services';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { GridLoader } from 'react-spinners';

export const EditProfile = () => {
  const { id } = useParams();

  const { data, isLoading } = useQuery({
    queryFn: () => userService.adminGetUserProfile(id),
    queryKey: ['admin-fetch-profile', id],
    enabled: !!id
  });

  return (
    <div className="lg:w-[60%]">
      <Container>
        <Heading>Edit Corporate User Info</Heading>
        {isLoading && (
          <div className="flex justify-center">
            <GridLoader size={25} color={'#891c69'} />
          </div>
        )}
        {data && (
          <EditProfileForm
            user={{
              firstName: data?.user?.firstName ?? '',
              lastName: data?.user?.lastName ?? '',
              phone: data?.user?.phone ?? '',
              id: data?.user?._id ?? ''
            }}
          />
        )}
      </Container>
    </div>
  );
};
