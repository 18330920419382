import { transactionService } from 'services';
import { useQuery, useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import * as bankCodes from 'json/bankCodes.json';

export const useInitiateRequest = () => {
  const navigate = useNavigate();

  const initiateRequest = useMutation((data) => transactionService.initiateTransaction(data), {
    onSuccess: () => {
      navigate('/transaction-requests');
    }
  });

  const { data: bankList } = useQuery({
    queryKey: ['bank-lists'],
    queryFn: () => {
      return bankCodes.default;
    }
  });

  return { bankList, initiateRequest };
};
