import { Button } from 'components/Button/Button';

export const AuthorizerActions = ({ disabled, action, canAuthorize }) => {
  return canAuthorize ? (
    <>
      <Button
        variant="success"
        disabled={disabled}
        onClick={() => {
          action('authorizer-approve');
        }}
      >
        Authorize
      </Button>
      <Button
        variant="danger"
        disabled={disabled}
        onClick={() => {
          action('authorizer-decline');
        }}
      >
        Decline
      </Button>
    </>
  ) : null;
};
