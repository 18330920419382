import { useMutation, useQuery } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import { Button } from 'components/Button/Button';
import { Input } from 'components/Form/Input/Input';
import { mandateService, userService } from 'services';
import { useNavigate, useParams } from 'react-router-dom';
import { Select } from 'components/Form/Select/Select';
import { useCallback, useMemo } from 'react';

export const MandateRuleForm = ({ defaultValues = {}, type = 'create' }) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const {
    register,
    handleSubmit,
    control,
    formState: { errors }
  } = useForm({
    defaultValues: { ...defaultValues }
  });

  const { data: authorizers } = useQuery({
    queryKey: ['authorizers'],
    queryFn: () =>
      userService.getBranchUsers({
        privilege: 'authoriser'
      })
  });

  const { data: verifiers } = useQuery({
    queryKey: ['verifiers'],
    queryFn: () =>
      userService.getBranchUsers({
        privilege: 'verifier'
      })
  });

  const sanitizeAuthorizers = useMemo(() => {
    if (authorizers) {
      return authorizers.filter((authorizer) => authorizer.isVerified && !authorizer.disabled);
    }
    return [];
  }, [authorizers]);

  const sanitizeVerifiers = useMemo(() => {
    if (verifiers) {
      return verifiers.filter((verifier) => verifier.isVerified && !verifier.disabled);
    }
    return [];
  }, [verifiers]);

  const transformData = useCallback((data) => {
    const users = [];
    data.forEach((user) => {
      users.push({
        label: `${user.firstName} ${user.lastName}`,
        value: user._id
      });
    });
    return users;
  }, []);

  const { isLoading, mutate } = useMutation(
    (data) =>
      type === 'create'
        ? mandateService.create(data)
        : mandateService.update({ name: data.name, verifiers: data.verifiers }, id),
    {
      onSuccess: () => {
        navigate('/settings/mandate-rule');
      }
    }
  );

  const onSubmit = (data) => {
    const payload = {
      name: data.name,
      minAmount: parseFloat(data.minAmount),
      maxAmount: parseFloat(data.maxAmount),
      verifiers: data.verifiers.map((verifier) => verifier.value),
      authoriser: data.authoriser.value
    };
    mutate(payload);
  };

  return (
    <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
      <Input
        label="Name"
        id="name"
        {...register('name', { required: true })}
        error={errors.name && 'Name is required'}
      />
      <Input
        label="Min Amount"
        id="min_amount"
        disabled={type !== 'create'}
        {...register('minAmount', { required: true })}
        error={errors.minAmount && 'Min Amount is required'}
      />
      <Input
        label="Max Amount"
        id="max_amount"
        disabled={type !== 'create'}
        {...register('maxAmount', { required: true })}
        error={errors.maxAmount && 'Max Amount is required'}
      />
      <Select
        label="Verifiers"
        name="verifiers"
        control={control}
        options={transformData(sanitizeVerifiers)}
        isMulti
        error={errors.verifiers && 'verifiers are required'}
      />
      <Select
        label="Authorizer"
        name="authoriser"
        control={control}
        options={transformData(sanitizeAuthorizers)}
        error={errors.authoriser && 'Authorizer are required'}
      />
      <div className="pt-5">
        <Button type="submit" variant="primary" disabled={isLoading} isFullWidth>
          Submit
        </Button>
      </div>
    </form>
  );
};
