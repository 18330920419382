import { Button } from 'components/Button/Button';

export const VerifierActions = ({ disabled, action, canVerify, canChangeDecision }) => {
  const { canReDecline, canReVerify } = canChangeDecision;
  return (
    <>
      {canVerify ? (
        <>
          <Button
            variant="success"
            disabled={disabled}
            onClick={() => {
              action('verifier-approve');
            }}
          >
            Verify
          </Button>
          <Button
            variant="danger"
            disabled={disabled}
            onClick={() => {
              action('verifier-decline');
            }}
          >
            Decline
          </Button>
        </>
      ) : null}
      {canReDecline || canReVerify ? (
        <Button
          variant={canReDecline ? 'danger' : 'success'}
          disabled={disabled}
          onClick={() => {
            action(canReDecline ? 'verifier-decline' : 'verifier-approve');
          }}
        >
          Change decision
        </Button>
      ) : null}
    </>
  );
};
