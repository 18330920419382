import React from 'react';
import {
  HomeIcon,
  CogIcon,
  BriefcaseIcon,
  DocumentMagnifyingGlassIcon,
  ChartPieIcon,
  ArrowLeftOnRectangleIcon,
  UserIcon,
  BanknotesIcon,
  ClockIcon,
  HandThumbUpIcon
} from '@heroicons/react/24/outline';
import { NavLinks } from './NavLink';
import { DropDown } from 'components/DropDown/DropDown';
import { DropDownItems } from 'components/DropDown/DropDownItems';
import { Link } from 'react-router-dom';
import { useModal } from 'hooks';
import LogoutPrompt from 'components/LogoutPrompt/LogoutPrompt';
import { usePrivilege } from 'hooks';

export const NavItem = ({ closeSideBar }) => {
  const { Modal, showModal } = useModal();
  const handleLogout = () => {
    closeSideBar();
    showModal();
  };

  const { hasPrivilege } = usePrivilege();
  const canInitiateRequest = hasPrivilege(['initiator']);
  const canAuthoriseRequests = hasPrivilege(['authoriser']);
  const canVerifyRequest = hasPrivilege(['verifier']);
  const canViewAssignedRequests = !!canAuthoriseRequests || !!canVerifyRequest;

  return (
    <div className="h-[90%] overflow-y-auto side-bar">
      <nav className="flex-1 space-y-1 px-2 pb-4">
        <NavLinks
          to="/dashboard"
          name="Dashboard"
          icon={<HomeIcon />}
          current={true}
          onClick={closeSideBar}
        />
        <DropDown
          title="Transfer Request"
          text="text-white  mt-5"
          icon={
            <BanknotesIcon className="mr-3 h-6 w-6 flex-shrink-0 text-white" aria-hidden="true" />
          }
        >
          <div>
            {!!canInitiateRequest && (
              <>
                <DropDownItems onClick={closeSideBar}>
                  <Link to="transaction-requests/initiate" onClick={closeSideBar} className="block">
                    Single Transfer
                  </Link>
                </DropDownItems>
                <DropDownItems onClick={closeSideBar}>
                  <Link
                    to="transaction-requests/batch-upload"
                    onClick={closeSideBar}
                    className="block"
                  >
                    Batch Transfer
                  </Link>
                </DropDownItems>
                <DropDownItems onClick={closeSideBar}>
                  <Link
                    to="transaction-requests/initiated"
                    onClick={closeSideBar}
                    className="block"
                  >
                    Due for Approval
                  </Link>
                </DropDownItems>
              </>
            )}
            <DropDownItems onClick={closeSideBar}>
              <Link to="transaction-requests/all" onClick={closeSideBar} className="block">
                All Transfer
              </Link>
            </DropDownItems>
            {!!canViewAssignedRequests && (
              <DropDownItems onClick={closeSideBar}>
                <Link to="transaction-requests/assigned" onClick={closeSideBar} className="block">
                  Assigned Transfer
                </Link>
              </DropDownItems>
            )}
          </div>
        </DropDown>
        <NavLinks to="/reports" icon={<ChartPieIcon />} name="Reports" onClick={closeSideBar} />
        <NavLinks
          to="/audit"
          icon={<DocumentMagnifyingGlassIcon />}
          name="Audit Trail"
          onClick={closeSideBar}
        />
        <NavLinks
          to="/transaction-history"
          icon={<ClockIcon />}
          name="Transaction History"
          onClick={closeSideBar}
        />
        <NavLinks to="/requests" icon={<HandThumbUpIcon />} name="Tickets" />
        <NavLinks
          to="/corporate"
          icon={<BriefcaseIcon />}
          name="Account Information"
          onClick={closeSideBar}
        />
        <NavLinks
          to="profile"
          icon={<UserIcon />}
          name="Profile"
          current={false}
          isActive
          onClick={closeSideBar}
        />
        <NavLinks
          to={'/settings/corporate-users'}
          icon={<CogIcon />}
          name="Account Settings"
          onClick={closeSideBar}
        />
        <p
          className="group flex items-center px-2 py-5 text-sm font-medium rounded-md text-white mt-5 relative cursor-pointer"
          onClick={() => {
            handleLogout();
          }}
        >
          <ArrowLeftOnRectangleIcon
            className="mr-3 h-6 w-6 flex-shrink-0 text-white"
            aria-hidden="true"
          />
          Logout
        </p>
        {Modal({
          children: <LogoutPrompt closeModal={showModal} />,
          size: 'sm'
        })}
      </nav>
    </div>
  );
};
