import { Logo } from 'components/Logo/Logo';
import classNames from 'classnames';
import banner from 'img/banner.png';
export const Auth = ({
  children, 
  title,
  pos = 'between',
  center,
  width = '',
  showBanner = true
}) => {
  return (
    <div className="bg-white">
      <div
        className={classNames(
          {
            'flex items-center justify-between px-10  mx-4 min-h': pos === 'between'
          },
          {
            'flex items-center justify-center px-10  mx-4 min-h ': pos === 'center'
          }
        )}>
        <div className="clip absolute top-0 right-0 w-[50%] h-[60%]"></div>
        <div className={`  ${width} flex-col flex justify-center lg:flex-none`}>
          <div className={showBanner === false ? 'my-10 pl-0' : 'my-10 lg:pl-20'}>
            <div className={` mb-4 ${center} relative z-10`}>
              <Logo />
            </div>
            <div>
              <div>
                <div
                  className={`mt-6 text-[31px] ${center} font-bold tracking-tight text-gray-900   duration-200ms ease in relative z-10`}>
                  {title}
                </div>
              </div>
            </div>
            <div className="mt-8">
              <div className="mt-6 relative z-10">{children}</div>
            </div>
          </div>
        </div>
        {showBanner === true ? (
          <div className="w-[43%] relative z-10 my-10 lg:block hidden lg:pr-20">
            <img src={banner} alt="" width={'100%'} loading="lazy" />
          </div>
        ) : null}
      </div>
    </div>
  );
};
