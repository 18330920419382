import { Heading } from 'components/Common/Header/Heading';
import { useQuery } from '@tanstack/react-query';
import { accountService } from 'services';
import { useStore } from 'hooks';
import { Badge } from 'components/Badge/Badge';

export const CorporateAccount = () => {
  const { user } = useStore();

  const { data } = useQuery({
    queryFn: () => accountService.getAccountInfo(user.organizationId.accountNumber[0]),
    queryKey: ['account-info'],
    enabled: !!user
  });

  return (
    <div className="p-6">
      <div className=" lg:w-[80%] w-full bg-white rounded shadow-sm border">
        <div className="">
          <div className="flex md:flex-col sm:flex-col lg:flex-row flex-col justify-between gap-2 px-6 pt-10">
            <div>
              <Heading>Account Information</Heading>
            </div>
          </div>
          <div className="mt-5 border-t border-gray-200">
            <dl className="divide-y divide-gray-200">
              <div className="py-5 flex  sm:flex-col md:flex-row flex-col lg:flex-row justify-between sm:py-5 px-4">
                <dt className="text-sm font-medium text-gray-500">Name</dt>
                <dd className="mt-1 flex  text-xl text-gray-900 sm:col-span-2 sm:mt-0 font-medium">
                  {user?.organizationId?.accountName}
                </dd>
              </div>
              <div className="py-5 flex  sm:flex-col md:flex-row flex-col items-start lg:items-center lg:flex-row justify-between sm:py-5 px-4">
                <dt className="text-sm font-medium text-gray-500 mb-3">{'Account Number(s)'}</dt>
                <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0 font-medium">
                  <span className=" -ml-2 lg:-ml-0 block">
                    {user?.organizationId?.accountNumber.map((ac) => (
                      <span className="mb-3 block" key={ac}>
                        <Badge>{ac}</Badge>
                      </span>
                    ))}
                  </span>
                </dd>
              </div>
              <div className="py-5 flex  sm:flex-col md:flex-row flex-col lg:flex-row justify-between sm:py-5 px-4">
                <dt className="text-sm font-medium text-gray-500">Email address</dt>
                <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0 font-medium">
                  <span className="flex-grow">{data?.Message?.Email}</span>
                </dd>
              </div>
              <div className="py-5 flex  sm:flex-col md:flex-row flex-col lg:flex-row justify-between sm:py-5 px-4">
                <dt className="text-sm font-medium text-gray-500">Branch</dt>
                <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0 font-medium">
                  <span className="flex-grow">{data?.Message?.Branch}</span>
                </dd>
              </div>
              <div className="py-5 flex  sm:flex-col md:flex-row flex-col lg:flex-row justify-between sm:py-5 px-4">
                <dt className="text-sm font-medium text-gray-500">Phone Number</dt>
                <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0 font-medium">
                  <span className="flex-grow">{data?.Message?.PhoneNumber}</span>
                </dd>
              </div>
              <div className="py-5 flex  sm:flex-col md:flex-row flex-col lg:flex-row justify-between sm:py-5 px-4">
                <dt className="text-sm font-medium text-gray-500">NUBAN</dt>
                <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0 font-medium">
                  <span className="flex-grow">{data?.Message?.NUBAN}</span>
                </dd>
              </div>
              <div className="py-5 flex  sm:flex-col md:flex-row flex-col lg:flex-row justify-between sm:py-5 px-4">
                <dt className="text-sm font-medium text-gray-500">Notification Preference</dt>
                <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0 font-medium">
                  <span className="flex-grow">{data?.Message?.NotificationPreference}</span>
                </dd>
              </div>
            </dl>
          </div>
        </div>
      </div>
    </div>
  );
};
