import { ForgotPassword } from 'pages/Auth/ForgotPassword/ForgotPassword';
import { ResetPassword } from 'pages/Auth/ResetPassword/ResetPassword';
import { Login } from 'pages/Auth/Login/Login';
import { VerifyAccount } from 'pages/Auth/VerifyAccount/VerifyAccount';
import { AccountOwnerVerifyAccount } from 'pages/Auth/VerifyAccount/AccountOwnerVerifyAccount';
import { CreateSecurityQuestion } from 'pages/Auth/CreateSecurityQuestion/CreateSecurityQuestion';
import Disabled from 'pages/Auth/Disabled/Disabled';

const routes = [
  {
    path: '/',
    element: <Login />
  },
  {
    path: 'forgot-password',
    element: <ForgotPassword />
  },
  {
    path: 'reset-password/:token',
    element: <ResetPassword />
  },
  {
    path: 'verify-account/:token',
    element: <VerifyAccount />
  },
  {
    path: 'auth/account/verify-account/:token',
    element: <AccountOwnerVerifyAccount />
  },
  {
    path: 'auth/create-security-questions',
    element: <CreateSecurityQuestion />
  },
  {
    path: 'auth/disabled-user',
    element: <Disabled />
  }
];

export { routes as authRoutes };
