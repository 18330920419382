import React from 'react';
import { Container } from 'components/Container/Container';
import { NavLink } from 'react-router-dom';
import { Heading } from 'components/Common/Header/Heading';
export const SettingsWrapper = ({ children }) => {
  return (
    <div className="p-5">
      <div className="mb-6">
        <Heading>Settings</Heading>
        <p className="mb-3 mt-2">Kindly make your changes below.</p>
      </div>
      <Container>
        <div className="flex sm:flex-col flex-col md:flex-row lg:flex-row gap-4 font-medium capitalize my-4 lg:border py-3 fit rounded lg:shadow lg:bg-gray-100 space-y-4 lg:space-y-0">
          <NavLink
            to="/settings/corporate-users"
            className={({ isActive }) => (isActive ? 'after relative px-2' : 'px-2')}
          >
            Corporate users
          </NavLink>
          <NavLink
            to="/settings/mandate-rule"
            className={({ isActive }) => (isActive ? 'after relative px-2' : 'px-2')}
          >
            Mandate rule
          </NavLink>
          <NavLink
            to="/settings/security"
            className={({ isActive }) => (isActive ? 'after relative px-2' : 'px-2')}
          >
            Security
          </NavLink>
        </div>
        <div>{children}</div>
      </Container>
    </div>
  );
};
