import http from 'plugins/axios';
import { notification } from 'utils';
import { PER_PAGE } from 'constants/pagination';

class UserService {
  async getProfile() {
    try {
      const { data } = await http.get('/api/users/profile');
      return data;
    } catch (error) {
      throw new Error(error);
    }
  }

  async getBranchUsers(params) {
    try {
      const response = await http.get('/api/users/allbranchusers', {
        params: { ...params, perPage: PER_PAGE }
      });
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  }

  async createCorporateUser(data) {
    try {
      const response = await http.post('/api/auth/register', data);
      notification('User created successfully');
      return response.data;
    } catch (error) {
      notification(error.response?.data?.message ?? 'Something went wrong', 'error');
      throw new Error(error);
    }
  }

  async updatePrivilege(data) {
    try {
      const response = await http.put('/api/users/userPrivilege', data);
      notification('Privilege updated successfully');
      return response.data;
    } catch (error) {
      notification(error?.response?.data?.message ?? 'Something went wrong', 'error');
      throw new Error(error);
    }
  }

  async adminUpdateProfile(data) {
    try {
      const response = await http.put('/api/users/profile', data);
      notification('User updated successfully');
      return response.data;
    } catch (error) {
      notification(error?.response?.data?.message ?? 'Something went wrong', 'error');
      throw new Error(error);
    }
  }

  async adminGetUserProfile(id) {
    try {
      const response = await http.get(`/api/users/profile/${id}`);
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  }
}

export const userService = new UserService();
