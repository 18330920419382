/* eslint-disable no-unused-vars */
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/24/outline';
// import { Button } from 'components/Button/Button';
import { Auth } from 'components/Layout';
import { accountService } from 'services';

export const AccountOwnerVerifyAccount = () => {
  const { token } = useParams();

  const { isSuccess, status, isIdle, isLoading } = useQuery({
    queryKey: ['verify-account-owner'],
    queryFn: () => accountService.verifyAccount(token),
    enabled: !!token
  });

  console.log(
    '🚀 ~ file: AccountOwnerVerifyAccount.js:17 ~ AccountOwnerVerifyAccount ~ status:',
    status
  );

  return (
    <Auth
      title={
        <>
          {(isIdle || isLoading) && (
            <span className="flex items-center justify-between">Verifying Account ...</span>
          )}
          {status === 'error' && (
            <span className="flex items-center justify-between">
              Account Verification Failed
              <XCircleIcon className="text-red-500 w-12 h-12 flex-shrink-0" />
            </span>
          )}
          {status === 'success' && (
            <span className="flex items-center justify-between">
              Account Verification Successful
              <CheckCircleIcon className="text-green-500 w-12 h-12 flex-shrink-0" />
            </span>
          )}
        </>
      }
    >
      {/* {isSuccess && (
        <div className="pt-3">
          <Button
            isFullWidth
            type="button"
            onClick={() =>
              navigate('/auth/create-security-questions', {
                state: { email: token, withPassword: true }
              })
            }>
            Create secret questions
          </Button>
        </div>
      )} */}
    </Auth>
  );
};
