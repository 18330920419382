import React from 'react';
import { Input } from 'components/Form/Input/Input';
import { Button } from 'components/Button/Button';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { userService } from 'services';
import { useStore } from 'hooks';

export const EditProfileForm = ({ user }) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { user: currentUser } = useStore();
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    defaultValues: { ...user }
  });

  const { mutate, isLoading } = useMutation({
    mutationFn: (data) => userService.adminUpdateProfile(data),
    onSuccess: () => {
      if (currentUser._id === user.id) {
        queryClient.invalidateQueries({
          queryKey: ['userProfile']
        });
      }
      navigate(-1);
    }
  });

  const onSubmit = (data) => {
    mutate({ ...data, id: user.id });
  };

  return (
    <form className="mt-5" onSubmit={handleSubmit(onSubmit)}>
      <div className="divide-y-blue-gray-200 mt-6 space-y-8 divide-y">
        <div className="grid grid-cols-1 gap-y-6 sm:grid-cols-6 sm:gap-x-6">
          <div className="sm:col-span-6">
            <h2 className="text-xl font-medium text-blue-gray-900">Corporate user</h2>
            <p className="mt-1 text-sm text-blue-gray-500">
              This information will be displayed publicly so be careful what you share.
            </p>
          </div>
          <div className="sm:col-span-3">
            <Input
              label="First name"
              type="text"
              {...register('firstName', { required: true })}
              error={errors['firstName'] ? 'This field is required' : ''}
            />
          </div>
          <div className="sm:col-span-3">
            <Input
              label="Last name"
              type="text"
              {...register('lastName', { required: true })}
              error={errors['lastName'] ? 'This field is required' : ''}
            />
          </div>
          <div className="sm:col-span-6">
            <Input
              label="Phone Number"
              type="text"
              {...register('phone', { required: false })}
              error={errors['phone'] ? 'This field is required' : ''}
            />
          </div>
        </div>
        <div className="flex justify-end gap-3 pt-8">
          <Button type="button" variant="outline" onClick={() => navigate(-1)} disabled={isLoading}>
            cancel
          </Button>
          <Button type="submit" disabled={isLoading}>
            Update
          </Button>
        </div>
      </div>
    </form>
  );
};
