import { useState } from 'react';
import { Button } from 'components/Button/Button';
import ContentLoader from 'react-content-loader';
import { UserPlusIcon } from '@heroicons/react/24/outline';
import { Link, useNavigate } from 'react-router-dom';
import { Container } from 'components/Container/Container';
import { UsersTable } from './UsersTable';
import { useQuery } from '@tanstack/react-query';
import { userService } from 'services';
import { EmptyState } from 'components/EmptyState/EmptyState';
import { usePrivilege } from 'hooks';
import SearchFilter from 'components/Form/SearchFilter/SearchFilter';
import Pagination from 'components/Pagination/Pagination';
import { useTableSerialNumber } from 'hooks/useTableSerialNumber';

export const CorporateUsers = () => {
  const navigate = useNavigate();
  const { hasPrivilege } = usePrivilege();
  const [searchValue, setSearchValue] = useState(undefined);
  const hasAdminPrivilege = !!hasPrivilege(['admin']);
  const [page, setPage] = useState(1);

  const { data, isLoading, isFetching, refetch } = useQuery({
    queryKey: ['getMyBranchUsers', page],
    queryFn: () => userService.getBranchUsers({ withPagination: true, page, search: searchValue })
  });

  const initialSerialNumber = useTableSerialNumber(page);

  const Render = () => {
    if (data?.users?.length === 0) {
      return (
        <EmptyState
          title="No Users found"
          description="There are no users in this branch. Click the button below to create one."
          showWAction={hasAdminPrivilege}
          action={{
            label: 'Create user',
            onClick: () => navigate('/settings/corporate-users/create-user')
          }}
        />
      );
    } else {
      return <UsersTable users={data?.users ?? []} initialSerialNumber={initialSerialNumber} />;
    }
  };

  return (
    <div>
      <Container>
        <div className="lg:flex items-center md:block">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-semibold text-gray-900">Users</h1>
            <p className="mt-2 text-sm text-gray-700">
              A list of Corporate users in your account including their name, title, email and role.
            </p>
          </div>
          <div className="w-25 mt-4">
            {hasAdminPrivilege && (
              <Link to="create-user">
                <Button>
                  Add user <UserPlusIcon width="20px" />
                </Button>
              </Link>
            )}
          </div>
        </div>

        <SearchFilter
          placeholder={'Search by name or email....'}
          value={searchValue}
          setValue={setSearchValue}
          onSearch={refetch}
        />

        {isLoading || isFetching ? (
          <div className="mt-5">
            <ContentLoader viewBox="0 0 380 70">
              <rect x="0" y="0" rx="5" ry="5" width="380" height="70" />
            </ContentLoader>
          </div>
        ) : (
          <>
            <Render />
            <Pagination
              totalItems={data?.meta?.totalCount ?? 0}
              handlePageClick={setPage}
              itemsPerPage={data?.meta?.perPage}
              currentPage={page}
            />
          </>
        )}
      </Container>
    </div>
  );
};
