import { Badge } from 'components/Badge/Badge';
import { naira } from 'utils/currencyFormatter';
import { Link } from 'react-router-dom';
import { Checkbox } from 'components/Form/Checkbox/Checkbox';
import { formatDate } from 'utils';

export const TransactionRequestTable = ({
  transactions,
  showCheckbox,
  transactionIds,
  setTransactionIds,
  initialSerialNumber
}) => {
  const updateTransactions = (target, transactionId) => {
    const checked = target.checked;
    if (checked) {
      const updatedTransactionIds = [...new Set([...transactionIds, transactionId])];
      setTransactionIds(updatedTransactionIds);
    } else {
      const updatedTransactionIds = transactionIds.filter((id) => id !== transactionId);
      setTransactionIds(updatedTransactionIds);
    }
  };

  return (
    <div role="list" className="divide-y divide-gray-200">
      <div className="relative lg:overflow-hidden overflow-x-auto mt-6">
        <table className="w-full text-sm text-left text-gray-500">
          <thead className="text-sm bg-gray-100 capitalize border text-gray-900 text-left font-semibold">
            <tr>
              {showCheckbox && (
                <td className="whitespace-nowrap pl-3 py-3 text-sm text-gray-500 border">
                  <Checkbox
                    onChange={(e) => {
                      if (e.target.checked) {
                        const ids = transactions.map((transaction) => transaction._id);
                        setTransactionIds(ids);
                      } else {
                        setTransactionIds([]);
                      }
                    }}
                    checked={transactionIds.length === transactions.length}
                  />
                </td>
              )}
              <th scope="col" className="p-3">
                S/N
              </th>
              <th scope="col" className="p-3">
                Originating Account
              </th>
              <th scope="col" className="p-3">
                Amount
              </th>
              <th scope="col" className="p-3">
                Reference
              </th>
              <th scope="col" className="p-3">
                Beneficiary
              </th>
              <th scope="col" className="p-3">
                Date
              </th>
              <th scope="col" className="p-3">
                Approval Status
              </th>
              <th scope="col" className="p-3">
                Transfer Status
              </th>

              <th scope="col" className="relative p-3">
                <span className="sr-only">Edit</span>
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            {transactions.map((transaction, i) => (
              <tr key={transaction?._id}>
                {showCheckbox && (
                  <td className="whitespace-nowrap pl-3 py-3 text-sm text-gray-500 border">
                    <Checkbox
                      onChange={(e) => updateTransactions(e.target, transaction?._id)}
                      checked={transactionIds.includes(transaction?._id)}
                    />
                  </td>
                )}
                <td className="whitespace-nowrap p-3 text-sm text-gray-500 border">
                  {initialSerialNumber + i}
                </td>
                <td className="whitespace-nowrap p-3 text-sm text-gray-500 border">
                  {transaction?.payerAccountNumber}
                </td>
                <td className=" p-3 text-sm text-gray-500 border">
                  <div className="text-gray-900 font-bold">{naira.format(transaction?.amount)}</div>
                </td>
                <td className="whitespace-nowrap p-3 text-sm text-gray-500 border">
                  {transaction?.transactionReference}
                </td>
                <td className=" p-3 text-sm text-gray-500 border">
                  <div>{transaction?.beneficiaryBankName}</div>
                  <div>{transaction?.beneficiaryAccountNumber}</div>
                  <div>{transaction?.beneficiaryAccountName}</div>
                </td>
                <td className=" p-3 text-sm text-gray-500 border">
                  {transaction?.createdAt && formatDate(transaction?.createdAt)}
                </td>
                <td className="whitespace-nowrap p-3 text-sm text-gray-500 border">
                  <Badge status={transaction?.status}>{transaction?.status}</Badge>
                </td>
                <td className="whitespace-nowrap p-3 text-sm text-gray-500 border">
                  <Badge status={transaction?.transferStatus}>{transaction?.transferStatus}</Badge>
                </td>

                <td className="relative p-4 text-sm font-medium border text-left">
                  <Link
                    to={`/transaction-requests/${encodeURIComponent(transaction._id)}`}
                    className="text-primary hover:text-indigo-900">
                    View
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
