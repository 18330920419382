import { Input } from 'components/Form/Input/Input';
import { Select } from 'components/Form/Select/Select';
import { Button } from 'components/Button/Button';
import { useForm } from 'react-hook-form';
import { useQuery } from '@tanstack/react-query';
import { accountService } from 'services';
import { useInitiateRequest } from '../hooks/useInitiateRequest';
import { useStore } from 'hooks';
import { useMemo, useState } from 'react';
import { Tooltip } from 'react-tooltip';
import { InformationCircleIcon } from '@heroicons/react/20/solid';
import { ExclamationTriangleIcon } from '@heroicons/react/20/solid';
import { notification } from 'utils';

export const InitiateRequestForm = () => {
  const { bankList, initiateRequest } = useInitiateRequest();
  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    getValues,
    formState: { errors }
  } = useForm();
  const [nameEnquiryLookupState, setNameEnquiryLookupState] = useState('pending');
  const [isDoingNameEnquiry, setIsDoingNameEnquiry] = useState(false);
  const accountNumber = watch('beneficiaryAccountNumber');
  const bankCode = watch('beneficiaryBank');
  const accountName = getValues('beneficiaryAccountName');
  const transferType = watch('type')?.value;
  const { user } = useStore();

  const callInterbankNameEnquiry = useMemo(() => {
    return transferType === 'inter-bank' && accountNumber?.length >= 10 && !!bankCode;
  }, [transferType, accountNumber, bankCode]);

  const callIntraBankNameEnquiry = useMemo(() => {
    return transferType === 'intra-bank' && accountNumber?.length >= 10;
  }, [transferType, accountNumber, bankCode]);

  useQuery({
    queryKey: ['name-enquiry', accountNumber, bankCode],
    queryFn: () => {
      setIsDoingNameEnquiry(true);
      setNameEnquiryLookupState('in-progress');
      return accountService.getNameEnquiry({
        bankCode: bankCode?.value,
        accountNumber: accountNumber
      });
    },
    enabled: !!callInterbankNameEnquiry,
    onSuccess: (data) => {
      if (data.IsSuccessful) {
        setNameEnquiryLookupState('successful');
        setValue('beneficiaryAccountName', data.Name);
        setValue('beneficiaryKYC', data.KYC);
        setValue('beneficiaryBVN', data.BVN);
        setValue('NIPSessionID', data.SessionID);
      } else {
        setNameEnquiryLookupState('error');
        setValue('beneficiaryAccountName', '');
        setValue('beneficiaryKYC', '');
        setValue('beneficiaryBVN', '');
        setValue('NIPSessionID', '');
      }
    },
    onError: () => {
      setNameEnquiryLookupState('error');
      setValue('beneficiaryAccountName', '');
      setValue('beneficiaryKYC', '');
      setValue('beneficiaryBVN', '');
      setValue('NIPSessionID', '');
    },
    onSettled: () => {
      setIsDoingNameEnquiry(false);
    }
  });

  useQuery({
    queryKey: ['bank-one-name-enquiry', accountNumber, bankCode],
    queryFn: () => {
      setIsDoingNameEnquiry(true);
      setNameEnquiryLookupState('in-progress');
      return accountService.getBankOneNameEnquiry({
        AccountNo: accountNumber
      });
    },
    enabled: !!callIntraBankNameEnquiry,
    onSuccess: (data) => {
      if (data.ResponseStatus !== 'Failed') {
        setNameEnquiryLookupState('successful');
        setValue('beneficiaryAccountName', data.Name);
        setValue('beneficiaryBVN', data.BVN);
      } else {
        setNameEnquiryLookupState('error');
        setValue('beneficiaryAccountName', '');
        setValue('beneficiaryKYC', '');
        setValue('beneficiaryBVN', '');
        setValue('NIPSessionID', '');
      }
    },
    onError: () => {
      setNameEnquiryLookupState('error');
      setValue('beneficiaryAccountName', '');
      setValue('beneficiaryKYC', '');
      setValue('beneficiaryBVN', '');
      setValue('NIPSessionID', '');
    },
    onSettled: () => {
      setIsDoingNameEnquiry(false);
    }
  });

  const { data } = useQuery({
    queryKey: ['account-balance', user?.organizationId?.accountNumber?.[0]],
    queryFn: () => accountService.getAccountByAccountNo(user?.organizationId?.accountNumber?.[0]),
    enabled: !!user
  });

  const accounts = useMemo(() => {
    return [
      {
        label: (
          <span>
            {user?.organizationId?.accountNumber?.[0]} -{' '}
            <strong>(N{data?.WithdrawableBalance})</strong>
          </span>
        ),
        value: user?.organizationId?.accountNumber?.[0]
      }
    ];
  }, [data, user]);

  const onSubmit = (data) => {
    if (!data.beneficiaryAccountName || data.beneficiaryAccountName === '') {
      notification('Unable to resolve account number', 'error');
      return;
    }
    const payload = {
      ...data,
      beneficiaryAccountType: data.beneficiaryAccountType.value,
      amount: Number(data.amount),
      beneficiaryBankName: data.beneficiaryBank.label,
      beneficiaryBankCode: data.beneficiaryBank.value,
      type: data.type.value,
      payerAccountNumber: data.payerAccountNumber.value
    };
    delete payload.beneficiaryBank;
    initiateRequest.mutate(payload);
  };

  return (
    <>
      <Tooltip anchorSelect=".tooltip-trigger" className="z-[1000] !bg-gray-500 text-gray-800" />
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
        <div className="space-y-6">
          <h3 className="text-lg font-medium leading-6 text-gray-900">Account Information</h3>
          <Input
            label="Amount"
            id="amount"
            {...register('amount', { required: true })}
            error={errors.amount && 'Amount is required'}
          />
          <Select
            label={
              <span>
                Select Originating Account
                <InformationCircleIcon
                  className="text-gray-400 h-4 w-4 inline-flex ml-2 tooltip-trigger"
                  data-tooltip-content="Select the account you want to debit from"
                />
              </span>
            }
            name="payerAccountNumber"
            control={control}
            options={accounts ?? []}
            error={errors.payerAccountNumber && 'Originating Account number is required'}
          />
        </div>

        {/* bank information */}
        <div className="space-y-6">
          <h3 className="text-lg font-medium leading-6 text-gray-900 mt-7">Bank information</h3>
          <Select
            label="Beneficiary Account Type"
            name="beneficiaryAccountType"
            control={control}
            options={[
              {
                value: 'savings',
                label: 'Savings'
              },
              {
                value: 'current',
                label: 'Current'
              }
            ]}
            error={errors.beneficiaryAccountType && 'Account type is required'}
          />
          <Select
            label="Transfer Type"
            name="type"
            control={control}
            onChange={(e) => {
              if (e.value === 'intra-bank') {
                setValue('beneficiaryBank', {
                  label: 'GMFB',
                  value: '51276'
                });
              } else {
                setValue('beneficiaryBank', {
                  label: '',
                  value: ''
                });
              }
            }}
            options={[
              {
                value: 'intra-bank',
                label: 'GMFB'
              },
              {
                value: 'inter-bank',
                label: 'Other Banks'
              }
            ]}
            error={errors.beneficiaryAccountType && 'Bank is required'}
          />
          {transferType === 'inter-bank' && (
            <Select
              label="Beneficiary Bank Name"
              name="beneficiaryBank"
              control={control}
              options={bankList?.map((bank) => ({ value: bank.code, label: bank.name })) || []}
              error={errors.beneficiaryBank && 'Bank name is required'}
            />
          )}
          <Input
            label="Beneficiary Account Number"
            id="account_number"
            {...register('beneficiaryAccountNumber', { required: true, min: 10 })}
            error={errors.beneficiaryAccountNumber && 'Account number is required'}
          />

          {nameEnquiryLookupState === 'successful' && (
            <div className="flex items-center space-x-2">
              <span className="text-sm text-gray-500">Account Name:</span>
              <span className="text-sm text-gray-900">{accountName ?? ''}</span>
            </div>
          )}

          {nameEnquiryLookupState === 'error' && (
            <div className="border-l-4 border-red-400 bg-red-50 p-4">
              <div className="flex">
                <div className="flex-shrink-0">
                  <ExclamationTriangleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                </div>
                <div className="ml-3">
                  <p className="text-sm text-red-700">
                    Unable to resolve account name. Please check the account number and try again.
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="pt-2">
          <Input
            label="Narration"
            id="narration"
            {...register('narration', { required: false })}
            error={errors.narration && 'Narration is required'}
          />
        </div>

        <div className="pt-8">
          <Button
            type="submit"
            disabled={isDoingNameEnquiry || initiateRequest.isLoading}
            isFullWidth
          >
            Submit
          </Button>
        </div>
      </form>
    </>
  );
};
