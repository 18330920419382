import { useMemo, useState } from 'react';
import ContentLoader from 'react-content-loader';
import { Button } from 'components/Button/Button';
import { Container } from 'components/Container/Container';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { transactionService } from 'services';
import { TransactionRequestTable } from './TranferRequestsTable';
import { EmptyState } from 'components/EmptyState/EmptyState';
import { usePrivilege } from 'hooks';
import SearchFilter from 'components/Form/SearchFilter/SearchFilter';
import Pagination from 'components/Pagination/Pagination';
import { BulkAction } from './BulkAction/BulkAction';
import { useTableSerialNumber } from 'hooks/useTableSerialNumber';

const RenderData = ({
  data,
  showCheckbox,
  transactionIds,
  setTransactionIds,
  initialSerialNumber
}) => {
  const { hasPrivilege } = usePrivilege();
  const navigate = useNavigate();
  const hasInitiatorPrivilege = !!hasPrivilege(['initiator']);

  if (data?.requests?.length === 0 || !data?.requests) {
    return (
      <EmptyState
        title="No transfer request found"
        description="You are yet any transaction request yet. Click the button below to get started."
        showWAction={hasInitiatorPrivilege}
        action={{
          label: 'Initiate transaction request',
          onClick: () => navigate('/transaction-requests/initiate')
        }}
      />
    );
  } else {
    return (
      <TransactionRequestTable
        transactions={data?.requests ?? []}
        showCheckbox={showCheckbox}
        transactionIds={transactionIds}
        setTransactionIds={setTransactionIds}
        initialSerialNumber={initialSerialNumber}
      />
    );
  }
};

export const TransactionRequest = () => {
  const [page, setPage] = useState(1);
  const [transactionIds, setTransactionIds] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState({ id: 6, name: 'All', value: null });
  const path = useLocation().pathname;
  const { hasPrivilege } = usePrivilege();
  const hasInitiatorPrivilege = !!hasPrivilege(['initiator']);

  const requestService = useMemo(() => {
    if (path.includes('assigned')) {
      return {
        title: 'Assigned Transfer Requests',
        url: transactionService.getAssignedRequests
      };
    } else if (path.includes('initiated')) {
      return {
        title: 'Initiated Transfer Requests',
        url: transactionService.getAllInitiatedRequests
      };
    }
    return {
      title: 'Transfer Requests',
      url: transactionService.getTransactionPerOrganization
    };
  }, [path]);

  const [searchValue, setSearchValue] = useState(undefined);
  const { data, isFetching, refetch } = useQuery({
    queryKey: ['transaction-requests', path, page, selectedStatus.value],
    queryFn: () =>
      requestService.url({
        page,
        status: selectedStatus.value,
        search: searchValue
      })
  });
  const initialSerialNumber = useTableSerialNumber(page);
  return (
    <div className="pt-4 overflow-auto px-5 lg:px-0">
      <div className="lg:pl-6 lg:pr-4 md:pr-0 md:pl-0">
        <Container>
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
              <h1 className="text-xl font-semibold text-gray-900">{requestService.title}</h1>
              <p className="text-sm text-gray-700">List of transfer requests in this account.</p>
            </div>
            <div className="mt-4 sm:mt-0 sm:ml-16 flex space-x-3 items-end">
              <div>
                <BulkAction
                  isAssignedRequest={path.includes('assigned')}
                  selectedStatus={selectedStatus}
                  setSelectedStatus={setSelectedStatus}
                  resetSelectedTransactions={() => setTransactionIds([])}
                  transactionIds={transactionIds}
                  successCB={() => {
                    setPage(1);
                    setSelectedStatus({ id: 6, name: 'All', value: null });
                    refetch();
                  }}
                />
              </div>
              {hasInitiatorPrivilege && (
                <>
                  <Link to="/transaction-requests/initiate">
                    <Button>Initiate Transfer</Button>
                  </Link>
                  <Link to="/transaction-requests/batch-upload">
                    <Button variant="black" type="button">
                      Batch Transfer
                    </Button>
                  </Link>
                </>
              )}
            </div>
          </div>

          <SearchFilter
            placeholder={'Search by reference or amount....'}
            value={searchValue}
            setValue={setSearchValue}
            onSearch={() => {
              page !== 1 ? setPage(1) : refetch();
            }}
          />

          <div className="mt-5">
            {isFetching ? (
              <ContentLoader viewBox="0 0 380 70">
                <rect x="0" y="0" rx="5" ry="5" width="380" height="70" />
              </ContentLoader>
            ) : (
              <>
                <RenderData
                  initialSerialNumber={initialSerialNumber}
                  data={data}
                  showCheckbox={
                    (path.includes('assigned') &&
                      ['Pending', 'In Progress', 'Awaiting Authorization'].includes(
                        selectedStatus?.name
                      )) ||
                    false
                  }
                  transactionIds={transactionIds}
                  setTransactionIds={setTransactionIds}
                />
                <Pagination
                  totalItems={data?.meta?.total ?? 0}
                  handlePageClick={setPage}
                  itemsPerPage={data?.meta?.perPage}
                  currentPage={page}
                />
              </>
            )}
          </div>
        </Container>
      </div>
    </div>
  );
};
