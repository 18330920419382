import { useMemo, useState } from 'react';
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { useStore, useModal } from 'hooks';
import { transactionService } from 'services';

export const useTransactionDetails = () => {
  const { id } = useParams();
  const { user } = useStore();
  const queryClient = useQueryClient();
  const { showModal, Modal } = useModal();
  const [actionType, setActionType] = useState(null);

  const { data: trnxDetails } = useQuery({
    queryKey: ['transaction-requests', id],
    queryFn: () => transactionService.getTransactionById(id),
    enabled: !!id
  });

  const canVerify = useMemo(() => {
    if (!['pending', 'in progress'].includes(trnxDetails?.status)) {
      return false;
    }

    const verifiers = trnxDetails.mandate?.verifiers ?? [];

    const isAVerifier = verifiers.some(({ _id }) => _id === user?._id);

    if (!isAVerifier) {
      return false;
    }

    const hasTakenAction = trnxDetails?.verifiersAction?.some(
      ({ verifierID }) => verifierID === user?._id
    );

    return !hasTakenAction;
  }, [trnxDetails, user]);

  const canChangeDecision = useMemo(() => {
    const statuses = ['awaiting authorization', 'in progress'];
    if (!statuses.includes(trnxDetails?.status)) {
      return false;
    }

    const hasUserRejected = trnxDetails?.verifiersAction?.find(
      ({ verifierID, status }) => verifierID === user?._id && status === 'rejected'
    );

    const hasUserVerified = trnxDetails?.verifiersAction?.find(
      ({ verifierID, status }) => verifierID === user?._id && status === 'verified'
    );

    return {
      canReVerify: !!hasUserRejected,
      canReDecline: !!hasUserVerified
    };
  }, [trnxDetails, user]);

  const canAuthorize = useMemo(() => {
    if (trnxDetails?.status !== 'awaiting authorization') {
      return false;
    }
    const authoriser = trnxDetails.mandate?.authoriser ?? null;
    return authoriser?._id === user?._id;
  }, [trnxDetails, user]);

  // mutations
  const authorizerAction = useMutation({
    mutationFn: (payload) => transactionService.authorizerAction(payload),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['transaction-requests', id],
        exact: true
      });
      showModal();
    }
  });
  const verifierAction = useMutation({
    mutationFn: (payload) => transactionService.verifierAction(payload),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['transaction-requests', id],
        exact: true
      });
      showModal();
    }
  });

  // util functions
  const showModalAndUpdateAction = (action) => {
    setActionType(action);
    showModal();
  };
  const takeAction = (payload) => {
    switch (actionType) {
      case 'authorizer-approve':
        authorizerAction.mutate({ ...payload, status: 'approve' });
        break;
      case 'authorizer-decline':
        authorizerAction.mutate({ ...payload, status: 'decline' });
        break;
      case 'verifier-approve':
        verifierAction.mutate({ ...payload, status: 'approve' });
        break;
      case 'verifier-decline':
        verifierAction.mutate({ ...payload, status: 'decline' });
        break;
      default:
        break;
    }
  };
  const getLoadingStatus = () => {
    return authorizerAction.isLoading || verifierAction.isLoading;
  };

  const getModalTitle = () => {
    switch (actionType) {
      case 'authorizer-approve':
        return 'Authorize Transaction';
      case 'authorizer-decline':
        return 'Decline Transaction';
      case 'verifier-approve':
        return 'Verify Transaction';
      case 'verifier-decline':
        return 'Decline Transaction';
      default:
        break;
    }
  };

  return {
    trnxDetails,
    canVerify,
    canChangeDecision,
    canAuthorize,
    showModalAndUpdateAction,
    takeAction,
    getLoadingStatus,
    getModalTitle,
    Modal
  };
};
