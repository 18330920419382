import { Fragment } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { Button } from 'components/Button/Button';
import { useModal, usePrivilege } from 'hooks';
import { FeedbackForm } from '../TransferRequestDetails/components/FeedbackForm';
import { useMutation } from '@tanstack/react-query';
import { transactionService } from 'services';

const approvalStatus = [
  { id: 6, name: 'All', value: null },
  { id: 3, name: 'Pending', value: 'pending' },
  { id: 2, name: 'In Progress', value: 'in progress' },
  { id: 1, name: 'Awaiting Authorization', value: 'awaiting authorization' },
  { id: 4, name: 'Declined', value: 'declined' },
  { id: 5, name: 'Approved', value: 'approved' }
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export const BulkAction = ({
  isAssignedRequest,
  selectedStatus,
  setSelectedStatus,
  resetSelectedTransactions,
  transactionIds,
  successCB
}) => {
  const { showModal, Modal } = useModal();

  const getModalTitle = () => {
    switch (selectedStatus.name) {
      case 'Pending':
      case 'In Progress':
        return 'Verify Transactions';

      case 'Awaiting Authorization':
        return 'Authorize Transactions';

      default:
        break;
    }
  };

  const handleBulkAuthorizeTransaction = useMutation({
    mutationFn: (payload) => transactionService.bulkAuthorizeTransaction(payload),
    onSuccess: () => {
      showModal();
      successCB();
    }
  });

  const handleBulkVerifyTransaction = useMutation({
    mutationFn: (payload) => transactionService.bulkVerifyTransaction(payload),
    onSuccess: () => {
      showModal();
      successCB();
    }
  });

  const takeAction = (payload) => {
    switch (selectedStatus.name) {
      case 'Pending':
      case 'In Progress':
        handleBulkVerifyTransaction.mutate({
          transactionIds,
          otp: payload.otp,
          batchId: payload.batchID
        });
        break;
      case 'Awaiting Authorization':
        handleBulkAuthorizeTransaction.mutate({
          transactions: transactionIds,
          otp: payload.otp,
          batchId: payload.batchID
        });
        break;
      default:
        break;
    }
  };

  const { hasPrivilege } = usePrivilege();
  const canAuthoriseRequests = hasPrivilege(['authoriser']);
  const canVerifyRequests = hasPrivilege(['verifier']);

  return (
    <>
      <div className="flex items-end">
        <div>
          <Listbox
            value={selectedStatus}
            onChange={(value) => {
              setSelectedStatus(value);
              resetSelectedTransactions();
            }}
            className="flex flex-col">
            {({ open }) => (
              <>
                <Listbox.Label className="block text-sm font-medium leading-6 text-gray-900">
                  Filter by approval status
                </Listbox.Label>
                <div className="relative mt-1">
                  <Listbox.Button className="relative w-full cursor-default min-h-[36px] min-w-[140px] rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-primary sm:text-sm sm:leading-6">
                    <span className="block truncate">{selectedStatus?.name ?? ''}</span>
                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                      <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                    </span>
                  </Listbox.Button>

                  <Transition
                    show={open}
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0">
                    <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                      {approvalStatus.map((status) => (
                        <Listbox.Option
                          key={status.id}
                          className={({ active }) =>
                            classNames(
                              active ? 'bg-primary text-white' : 'text-gray-900',
                              'relative cursor-default select-none py-2 pl-3 pr-9'
                            )
                          }
                          value={status}>
                          {({ selected, active }) => (
                            <>
                              <span
                                className={classNames(
                                  selected ? 'font-semibold' : 'font-normal',
                                  'block truncate'
                                )}>
                                {status?.name}
                              </span>

                              {selected ? (
                                <span
                                  className={classNames(
                                    active ? 'text-white' : 'text-primary',
                                    'absolute inset-y-0 right-0 flex items-center pr-4'
                                  )}>
                                  <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                </span>
                              ) : null}
                            </>
                          )}
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </Transition>
                </div>
              </>
            )}
          </Listbox>
        </div>
        {['Awaiting Authorization', 'Pending', 'In Progress'].includes(selectedStatus?.name) &&
          isAssignedRequest && (
            <div className="ml-4">
              {canAuthoriseRequests &&
                ['Awaiting Authorization'].includes(selectedStatus?.name) && (
                  <Button variant="primary" type="button" onClick={showModal}>
                    Bulk Authorize
                  </Button>
                )}
              {canVerifyRequests && ['Pending', 'In Progress'].includes(selectedStatus?.name) && (
                <Button variant="primary" type="button" onClick={showModal}>
                  Bulk Verify
                </Button>
              )}
            </div>
          )}
      </div>
      {Modal({
        children: (
          <FeedbackForm
            callback={takeAction}
            transactionIds={transactionIds}
            title={getModalTitle()}
            isSubmitting={
              handleBulkAuthorizeTransaction.isLoading || handleBulkVerifyTransaction.isLoading
            }
          />
        ),
        size: 'lg'
      })}
    </>
  );
};
