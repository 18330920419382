import { useState } from 'react';
import { Charts } from 'pages/Reporting/Chart/Chart';
import { Heading } from 'components/Common/Header/Heading';
import { analyticsService } from 'services';
import { useQuery } from '@tanstack/react-query';
import SwitchAccount from 'components/SwitchAccount/SwitchAccount';
import { useAccount } from 'hooks';
import ContentLoader from 'react-content-loader';
import { Container } from 'components/Container/Container';

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

export const Report = () => {
  const { accounts, switchAccount, account } = useAccount();
  const [chartData, setChartData] = useState([]);
  const [year, setYear] = useState(new Date());

  const { isFetching, data } = useQuery({
    queryKey: ['analytics', account, year],
    queryFn: () => analyticsService.getAnalysis(account, year.getFullYear()),
    enabled: !!account,
    onSuccess: (data) => {
      const disbursedAmount = data.disbursements;
      const formattedData = [];
      months.forEach((month) => {
        const disbursed = disbursedAmount.find((disbursed) => disbursed.month === month);
        if (disbursed) {
          formattedData.push({
            month,
            amount: disbursed.amount
          });
        } else {
          formattedData.push({
            month,
            amount: 0
          });
        }
      });
      setChartData(formattedData);
    }
  });

  return (
    <div className="my-7 ml-2 px-5">
      <div className="py-5">
        <div className="mb-5 flex items-center justify-between">
          <Heading>Reports</Heading>
          <SwitchAccount account={account} accounts={accounts} switchAccount={switchAccount} />
        </div>
        <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3 mb-7"></div>
        <Container>
          {isFetching ? (
            <ContentLoader viewBox="0 0 380 70">
              <rect x="0" y="0" rx="5" ry="5" width="380" height="70" />
            </ContentLoader>
          ) : (
            <Charts
              data={chartData}
              setYear={setYear}
              year={year}
              totalAmount={data?.totalAmount ?? 0}
            />
          )}
        </Container>
      </div>
    </div>
  );
};
