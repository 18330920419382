import { Route, Routes, Outlet, Navigate } from 'react-router-dom';
import { Dashboard } from 'pages/Home/Dashboard';
import { TransactionRequest } from 'pages/TransferRequests/TransferRequests';
import { TransactionDetails } from 'pages/TransferRequests/TransferRequestDetails/TransactionDetails';
import { Audit } from 'pages/AuditTrail/AuditTrail';
import { CorporateAccount } from 'pages/CorporateAccount/CorporateAccount';
import { Report } from 'pages/Reporting/Reports';
import { InitiateRequest } from 'pages/TransferRequests/Initiate/InitiateRequest';
import { AuthLayout } from '../../components/Layout/AuthLayout';
import { BatchUpload } from 'pages/TransferRequests/BatchUpload/BatchUpload';
import { MandateRule } from 'pages/MandateRule/MandateRule';
import { CreateMandateRule } from 'pages/MandateRule/CreateMandateRule/CreateMandateRule';
import { UpdateMandateRule } from 'pages/MandateRule/UpdateMandateRule/UpdateMandateRule';
import { EditProfile } from 'pages/Profile/EditProfile/EditProfile';
import { CreateCorporateUser } from 'pages/Settings/User/CreateCorporateUser';
import { Profile } from 'pages/Profile/Profile';
import { ErrorPage } from 'pages/Auth/ErrorPage/ErrorPage';
import { Security } from 'pages/Settings/Security/Security';
import { CorporateUsers } from 'pages/Settings/User/CorporateUsers';
import { authRoutes } from 'config/Router/routes/Auth.routes';
import { PrivateOutlet } from './PrivateOutLet';
import { SettingsWrapper } from 'pages/Settings/SettingsWrapper';
import { TransactionHistory } from 'pages/TransactionHistory/TransactionHistory';
import RequestTicketing from 'pages/Requests/RequestTicketing';
import AddRequest from 'pages/Requests/AddRequest/AddRequest';
import { RequestTicketingDetails } from 'pages/Requests/RequestDetails/RequestDetails';

export const Routing = () => {
  return (
    <>
      <Routes>
        {authRoutes.map(({ path, element }) => (
          <Route key={path} path={path} element={element} />
        ))}
        <Route element={<PrivateOutlet />}>
          <Route
            element={
              <AuthLayout>
                <Outlet />
              </AuthLayout>
            }
          >
            <Route
              element={
                <SettingsWrapper>
                  <Outlet />
                </SettingsWrapper>
              }
            >
              <Route path="settings">
                <Route index element={<CorporateUsers />} />
                <Route path="security" element={<Security />} />
                <Route path="corporate-users">
                  <Route index element={<CorporateUsers />} />
                  <Route path=":id/edit-user" element={<EditProfile />} />
                  <Route path="create-user" element={<CreateCorporateUser />} />
                </Route>
              </Route>
              <Route path="settings/mandate-rule">
                <Route index element={<MandateRule />} />
                <Route path="create" element={<CreateMandateRule />} />
                <Route path="update/:id" element={<UpdateMandateRule />} />
              </Route>
            </Route>
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="transaction-requests">
              <Route index element={<Navigate to="initiated" />} />
              <Route path="assigned" element={<TransactionRequest />} />
              <Route path="initiated" element={<TransactionRequest />} />
              <Route path="all" element={<TransactionRequest />} />
              <Route path="batch-upload" element={<BatchUpload />} />
              <Route path="initiate" element={<InitiateRequest />} />
              <Route path=":id" element={<TransactionDetails />} />
            </Route>

            <Route path="transaction-history">
              <Route index element={<TransactionHistory />} />
            </Route>
            <Route path="requests">
              <Route index element={<RequestTicketing />} />
              <Route path=":id" element={<RequestTicketingDetails />} />
              <Route path="add-request" element={<AddRequest />} />
            </Route>

            <Route path="profile">
              <Route index element={<Profile />} />
            </Route>

            <Route path="audit" element={<Audit />} />
            <Route path="corporate" element={<CorporateAccount />} />
            <Route path="reports" element={<Report />} />
          </Route>
        </Route>
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </>
  );
};
