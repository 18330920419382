import Balance from 'pages/Home/components/Balance';
import Header from 'pages/Home/components/Header';
import React from 'react';
import SwitchAccount from 'components/SwitchAccount/SwitchAccount';
import { Container } from 'components/Container/Container';
import { SubHeading } from 'components/Common/Header/SubHeading';
import { useAccount } from 'hooks';
import { useTransactionHistory } from 'pages/TransactionHistory/hooks/useTransactionHistory';
import { TransactionHistory } from './components/TransactionHistory';
import { Link } from 'react-router-dom';
import { Button } from 'components/Button/Button';

export const Dashboard = () => {
  const { accounts, switchAccount, account: selected } = useAccount();
  const { transactions } = useTransactionHistory({ PageSize: 10 });

  const setSelected = accounts?.map((act, i) => {
    return selected === act ? (
      <>
        <span>ACCOUNT NO({i + 1}) :</span>
        <span className="ml-1"> {selected}</span>
      </>
    ) : (
      ''
    );
  });

  return (
    <div>
      <Header title="Admin Officer" selected={setSelected}>
        <SwitchAccount account={selected} accounts={accounts} switchAccount={switchAccount} />
      </Header>
      <div className="px-6 py-8">
        <div className="grid grid-cols-12 space-y-6">
          <div className="col-span-12">
            <Balance />
          </div>
          <div className="col-span-12">
            <Container>
              <div className="flex lg:flex-row md:flex-row sm:flex-col flex-col justify-between lg:items-center md:items-center sm:items-start items-start space-y-4 ">
                <SubHeading>Transaction History</SubHeading>
                <Link to="/transaction-history">
                  <Button variant="black">View all</Button>
                </Link>
              </div>
              <div className="mt-5">
                <TransactionHistory transactions={transactions} />
              </div>
            </Container>
          </div>
        </div>
      </div>
    </div>
  );
};
