import ContentLoader from 'react-content-loader';
import { Container } from 'components/Container/Container';
import { TransactionHistoryTable } from './components/TransactionHistoryTable';
import { EmptyState } from 'components/EmptyState/EmptyState';
import { useModal } from 'hooks';
import { Button } from 'components/Button/Button';
import { ArrowDownIcon } from '@heroicons/react/20/solid';
import { DatePickerComponent } from 'components/Form/DatePicker/DateTimePicker';
import { useState } from 'react';
import { useTransactionHistory } from './hooks/useTransactionHistory';
import SwitchAccount from 'components/SwitchAccount/SwitchAccount';
import { useAccount } from 'hooks';
import Pagination from 'components/Pagination/Pagination';
import { useTableSerialNumber } from 'hooks/useTableSerialNumber';

export const RenderData = ({ data, initialSerialNumber }) => {
  if (!data?.IsSuccessful || data?.Message.length === 0) {
    return (
      <EmptyState
        title="No transactions"
        description="You are yet to make a transaction. Check back later."
      />
    );
  } else {
    return (
      <TransactionHistoryTable
        transactions={data?.Message?.data ?? []}
        initialSerialNumber={initialSerialNumber}
      />
    );
  }
};

export const TransactionHistory = () => {
  const { accounts, switchAccount, account: selected } = useAccount();
  const { Modal, showModal } = useModal();
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [page, setPage] = useState(1);

  const initialSerialNumber = useTableSerialNumber(page);
  const { accountStatement, transactions } = useTransactionHistory({ PageSize: 50, pageNo: page });

  return (
    <div className="pt-6 overflow-auto px-5 lg:px-0">
      <div className="lg:pl-6 lg:pr-4 md:pr-0 md:pl-0">
        <Container>
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
              <div className="flex flex-col lg:flex-row items-start lg:items-center justify-between space-y-5 lg:space-y-0">
                <div>
                  <h1 className="text-xl font-semibold text-gray-900">Transaction History</h1>
                  <p className="text-sm text-gray-700 mt-1">
                    List of transactions made from this account.
                  </p>
                  <h1 className="text-xl font-bold text-gray-900 my-2">
                    <i className="text-sm font-bold">ACCOUNT NO</i> {selected}
                  </h1>
                </div>
                <div className="lg:flex block items-end gap-3 lg:space-y-0 space-y-5">
                  <SwitchAccount
                    accounts={accounts}
                    account={selected}
                    switchAccount={switchAccount}
                  />
                  <Button
                    variant="black"
                    type="button"
                    onClick={() => {
                      showModal();
                    }}
                  >
                    Generate Account Statement
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-5">
            {transactions.isLoading ? (
              <ContentLoader viewBox="0 0 380 70">
                <rect x="0" y="0" rx="5" ry="5" width="380" height="70" />
              </ContentLoader>
            ) : (
              <>
                <RenderData data={transactions.data} initialSerialNumber={initialSerialNumber} />
                <Pagination
                  totalItems={transactions?.data?.Message?.page?.totalCount ?? 0}
                  handlePageClick={setPage}
                  currentPage={page}
                />
              </>
            )}
          </div>
        </Container>
      </div>
      {Modal({
        children: (
          <div className="space-y-6">
            <p className="font-medium">Generate Account Statement</p>
            <DatePickerComponent
              handleDate={setFromDate}
              label="Start Date"
              id="fromDate"
              value={fromDate}
            />
            <DatePickerComponent
              handleDate={setToDate}
              label="End Date"
              id="toDate"
              value={toDate}
            />
            <Button
              isFullWidth
              onClick={() => accountStatement.download({ fromDate, toDate, showModal })}
              disabled={accountStatement.downloading}
            >
              <div className="flex items-center gap-2">
                Download Account Statement
                <ArrowDownIcon className="w-5 h-5" />
              </div>
            </Button>
          </div>
        ),
        showCloseIcon: false
      })}
    </div>
  );
};
