import { Sidebar } from 'components/Navbar/Sidebar';
import React from 'react';
import { useState } from 'react';
export const AuthLayout = (prop) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const { children } = prop;
  return (
    <div>
      <Sidebar setSidebarOpen={setSidebarOpen} sidebarOpen={sidebarOpen} />
      <div className="Home">{children}</div>
    </div>
  );
};
