import React from 'react';
import SecurityQuestionForm from 'components/SecurityQuestion/SecurityQuestionForm';
import { useForm } from 'react-hook-form';
import { Button } from 'components/Button/Button';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { Input } from 'components/Form/Input/Input';

export const CreateSecurityQuestionForm = ({ showPasswordForm, service, extraPayload = {} }) => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    control,
    watch,
    getValues,
    formState: { errors }
  } = useForm();

  const { mutate, isLoading } = useMutation((data) => service(data), {
    onSuccess: () => {
      navigate('/');
    }
  });

  const onSubmit = (data) => {
    const secretQuestions = [
      {
        question: data['question-1'].value,
        answer: data['answer-1']
      },
      {
        question: data['question-2'].value,
        answer: data['answer-2']
      },
      {
        question: data['question-3'].value,
        answer: data['answer-3']
      }
    ];

    const payload = {
      ...extraPayload,
      secretQuestions,
      ...(showPasswordForm
        ? { password: data.password, confirm_password: data.confirm_password }
        : {})
    };

    mutate(payload);
  };

  return (
    <form className="space-y-12" onSubmit={handleSubmit(onSubmit)}>
      {showPasswordForm && (
        <>
          <Input
            label="Password"
            type="password"
            id="password"
            {...register('password', { required: true })}
            error={errors.password && 'Password is required'}
          />

          <Input
            label="Confirm Password"
            type="password"
            id="confirm_password"
            {...register('confirm_password', {
              validate: (value) => {
                const { password } = getValues();
                return password === value || 'Passwords should match!';
              }
            })}
            error={
              errors.confirm_password &&
              (errors.confirm_password?.message ?? 'Confirm Password is required')
            }
          />

          <hr />
        </>
      )}

      <SecurityQuestionForm control={control} errors={errors} register={register} watch={watch} />

      <Button type="submit" isFullWidth disabled={isLoading}>
        Submit
      </Button>
    </form>
  );
};
