import { Heading } from 'components/Common/Header/Heading';
import { Container } from 'components/Container/Container';
import { useState } from 'react';
import { RequestTable } from './components/RequestTable/RequestTable';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from 'components/Button/Button';
import { EmptyState } from 'components/EmptyState/EmptyState';
import { ticketService } from 'services';
import ContentLoader from 'react-content-loader';
import { useQuery } from '@tanstack/react-query';
import Pagination from 'components/Pagination/Pagination';
import { useTableSerialNumber } from 'hooks/useTableSerialNumber';
import SearchFilter from 'components/Form/SearchFilter/SearchFilter';

const RenderData = ({ data, initialSerialNumber }) => {
  const navigate = useNavigate();
  if (data?.tickets?.length === 0 || !data?.tickets) {
    return (
      <EmptyState
        title="No tickets found"
        description="You have not created any tickets yet."
        showAction
        action={{
          label: 'Create request',
          onClick: () => navigate('/requests/add-request')
        }}
      />
    );
  } else {
    return <RequestTable tickets={data.tickets ?? []} initialSerialNumber={initialSerialNumber} />;
  }
};

const RequestTicketing = () => {
  const [page, setPage] = useState(1);
  const [searchValue, setSearchValue] = useState(undefined);
  const { data, isLoading, refetch, isFetching } = useQuery({
    queryKey: ['get-tickets', page],
    queryFn: () => ticketService.getTickets({ page, topic: searchValue })
  });
  const initialSerialNumber = useTableSerialNumber(page);
  return (
    <div className="p-5">
      <Container>
        <Heading>All Request</Heading>
        <div className="flex justify-between  my-2  align-start lg:align-center flex-col lg:flex-row ">
          <p>List of request made in the system.</p>
          <Link to="/requests/add-request">
            <Button>Add Request</Button>
          </Link>
        </div>
        <SearchFilter
          placeholder={'Search...'}
          value={searchValue}
          setValue={setSearchValue}
          onSearch={() => {
            page !== 1 ? setPage(1) : refetch();
          }}
        />
        {isLoading || isFetching ? (
          <div className="mt-5">
            <ContentLoader viewBox="0 0 380 70">
              <rect x="0" y="0" rx="5" ry="5" width="380" height="70" />
            </ContentLoader>
          </div>
        ) : (
          <RenderData data={data} initialSerialNumber={initialSerialNumber} />
        )}
        <Pagination
          totalItems={data?.meta?.total ?? 0}
          handlePageClick={setPage}
          itemsPerPage={data?.meta?.perPage}
          currentPage={page}
        />
      </Container>
    </div>
  );
};
export default RequestTicketing;
