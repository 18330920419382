import { AuditTrail } from 'components/AuditTrail/AuditTrail';
import { auditService } from 'services';

export const Audit = () => {
  return (
    <div className="p-5 mb-24">
      <AuditTrail queryFunc={auditService.getOrganizationAuditTrails} />
    </div>
  );
};
