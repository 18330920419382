import http from 'plugins/axios';
import { notification } from 'utils';

class AccountService {
  async getAccountByAccountNo(accountNo) {
    try {
      const { data } = await http.get(`/api/bank/balance/${accountNo}`);
      return data;
    } catch (error) {
      throw new Error(error);
    }
  }
  async getTransactionHistory(accountNumber, params) {
    try {
      const { data } = await http.get(`/api/bank/history/${accountNumber}`, {
        params
      });
      return data;
    } catch (error) {
      throw new Error(error);
    }
  }
  async getAccountInfo(accountNumber) {
    try {
      const { data } = await http.get('/api/bank/info', {
        params: {
          accountNumber
        }
      });
      return data;
    } catch (error) {
      throw new Error(error);
    }
  }
  async getNameEnquiry(payload) {
    try {
      const { data } = await http.post('/api/bank/name-enquiry', payload);
      return data;
    } catch (error) {
      throw new Error(error);
    }
  }
  async getBankOneNameEnquiry(payload) {
    try {
      const { data } = await http.post('/api/bank/intra-bank/name-enquiry', payload);
      return data;
    } catch (error) {
      throw new Error(error);
    }
  }
  async verifyAccount(token) {
    try {
      const response = await http.get(`/api/account/verify-account/${token}`);
      return response;
    } catch (error) {
      notification(error.response.data.Message ?? 'Something went wrong', 'error');
      throw new Error(error);
    }
  }
  async getAccountStatement(accountNumber, params) {
    try {
      const { message } = await http.get(`/api/bank/statement/${accountNumber}`, {
        params
      });
      return message;
    } catch (error) {
      notification(error.response.data.message, 'error');
      throw new Error(error);
    }
  }
  async getCustomerInfo(customerId) {
    try {
      const data = await http.get(`/api/bank/detail/${customerId}`);
      return data;
    } catch (error) {
      throw new Error(error);
    }
  }
}

export const accountService = new AccountService();
