import { Avatar } from 'components/Avatar/Avatar';
import { useModal, usePrivilege } from 'hooks';

import CorporateDetails from './CorporateDetails';
import SwitchRoles from './SwitchRoles/SwitchRoles';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { authService } from 'services';
import { Dropdown } from 'flowbite-react';
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid';
import { useCallback, useState } from 'react';
import { Badge } from 'components/Badge/Badge';
export const UsersTable = ({ users, initialSerialNumber }) => {
  const { Modal, showModal } = useModal();
  const [userDetails, setUserDetails] = useState(null);
  const [user, setUser] = useState(null);
  const [viewUsers, setViewUsers] = useState('');
  const navigate = useNavigate();
  const { hasPrivilege } = usePrivilege();

  const { mutate } = useMutation({
    mutationFn: (email) => authService.resendVerificationLink(email)
  });
  const getUserStatus = useCallback((isDisabled) => {
    return isDisabled ? 'disabled' : 'active';
  }, []);
  const hasAdminPrivilege = !!hasPrivilege(['admin']);

  const handleView = (e) => {
    setViewUsers(e);
    showModal();
    setUserDetails('');
  };

  return (
    <>
      <div role="list" className="divide-y divide-gray-200">
        <div className="relative lg:overflow-hidden overflow-x-auto mt-6">
          <table className="w-full text-sm text-left text-gray-500">
            <thead className="text-xs bg-gray-100 uppercase border text-black">
              <tr>
                <th scope="col" className="p-3">
                  S/N
                </th>
                <th scope="col" className="p-3">
                  Name
                </th>
                <th scope="col" className="p-3">
                  Gender
                </th>
                <th scope="col" className="p-3">
                  Privilege
                </th>
                <th scope="col" className="p-3">
                  Status
                </th>
                <th scope="col" className="p-3">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {users.map((user, i) => (
                <tr key={user.email} className="border hover:bg-gray-50">
                  <td className="whitespace-nowrap p-3 border capitalize">
                    {initialSerialNumber + i}
                  </td>
                  <td className="p-3 border whitespace-nowrap">
                    <div className="flex items-start">
                      <Avatar name={`${user?.firstName} ${user?.lastName}`} />
                      <div className="pl-3">
                        <div className="text-gray-900 font-semibold capitalize break-words">
                          {user?.firstName} {user?.lastName}
                        </div>
                        {user?.email}
                      </div>
                    </div>
                  </td>
                  <td className="whitespace-nowrap p-3 border capitalize">{user.gender}</td>
                  <td className="whitespace-nowrap p-3 border capitalize">
                    {user.privileges?.[0]?.name}
                  </td>
                  <td className="whitespace-nowrap p-3 border capitalize">
                    <Badge status={getUserStatus(user?.disabled)}>
                      {getUserStatus(user?.disabled)}
                    </Badge>
                  </td>

                  <td className="p-3 border capitalize">
                    <Dropdown
                      label={<EllipsisVerticalIcon className="h-5 w-5 text-gray-600" />}
                      inline={true}
                      arrowIcon={false}
                    >
                      <Dropdown.Item
                        onClick={() => {
                          handleView(<CorporateDetails user={user} />);
                        }}
                      >
                        View Profile
                      </Dropdown.Item>
                      {hasAdminPrivilege && (
                        <>
                          <Dropdown.Item
                            onClick={() =>
                              navigate(`/settings/corporate-users/${user._id}/edit-user`)
                            }
                          >
                            Edit
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => {
                              setUser(user);
                              setUserDetails(`${user.firstName} ${user.lastName}`);
                              showModal();
                              setViewUsers('');
                            }}
                          >
                            Manage privilege
                          </Dropdown.Item>
                          {!user.isVerified && (
                            <Dropdown.Item onClick={() => mutate(user.email)}>
                              Resend Verification link
                            </Dropdown.Item>
                          )}
                        </>
                      )}
                    </Dropdown>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      {Modal({
        children: viewUsers ? (
          viewUsers
        ) : (
          <SwitchRoles userName={userDetails} user={user} avatar={<Avatar name={userDetails} />} />
        ),
        showCloseIcon: true,
        size: 'md'
      })}
    </>
  );
};
