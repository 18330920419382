import { SubHeading } from 'components/Common/Header/SubHeading';
import { Container } from 'components/Container/Container';
import { TransactionDetailsList } from './components/TransactionDetailsList';
import { VerifierTimeline } from './components/Timeline/VerifierTimeline';
import { FeedbackForm } from './components/FeedbackForm';
import { AuthorizerTimeline } from './components/Timeline/AuthorizerTimeline';
import { VerifierActions } from './components/VerifierActions';
import { AuthorizerActions } from './components/AuthorizerActions';
import { useTransactionDetails } from './hooks/useTransactionDetails';

export const TransactionDetails = () => {
  const {
    trnxDetails,
    canVerify,
    canChangeDecision,
    canAuthorize,
    showModalAndUpdateAction,
    takeAction,
    getLoadingStatus,
    getModalTitle,
    Modal
  } = useTransactionDetails();

  return (
    <>
      <div className="lg:grid md:block sm:block block grid-cols-12 gap-5 px-8 mt-8 pb-6">
        <div className="col-span-12 flex justify-between items-center">
          <div>
            <SubHeading>Transfer Request Details</SubHeading>
          </div>
          <div className="flex space-x-2">
            <VerifierActions
              disabled={getLoadingStatus()}
              action={showModalAndUpdateAction}
              canVerify={canVerify}
              canChangeDecision={canChangeDecision}
            />
            <AuthorizerActions
              disabled={getLoadingStatus()}
              action={showModalAndUpdateAction}
              canAuthorize={canAuthorize}
            />
          </div>
        </div>
        <div className="col-span-8">
          <Container>
            <TransactionDetailsList details={trnxDetails} />
          </Container>
        </div>
        <div className="col-span-4 space-y-6">
          <VerifierTimeline
            verifiers={trnxDetails?.mandate?.verifiers ?? []}
            decisions={trnxDetails?.verifiersAction ?? []}
          />
          <AuthorizerTimeline
            authorizer={trnxDetails?.mandate?.authoriser ?? {}}
            decision={trnxDetails?.authoriserAction ?? {}}
          />
        </div>
      </div>
      {Modal({
        children: (
          <FeedbackForm
            callback={takeAction}
            title={getModalTitle()}
            isSubmitting={getLoadingStatus()}
          />
        ),
        size: 'lg'
      })}
    </>
  );
};
