import { Container } from 'components/Container/Container';
import { Heading } from 'components/Common/Header/Heading';
import { FileUpload } from 'components/Form/FileUpload/FileUpload';
import { useConvertFileToJson, useModal } from 'hooks';
import { Button } from 'components/Button/Button';
import { CloudArrowUpIcon } from '@heroicons/react/24/outline';
import { useMutation } from '@tanstack/react-query';
import { transactionService, accountService } from 'services';
import { useNavigate } from 'react-router-dom';
import { Spinner } from 'components/Spinner/Spinner';
import { SubHeading } from 'components/Common/Header/SubHeading';
import { useState, useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Select } from 'components/Form/Select/Select';
import { Preview } from './Preview';
import { Error } from './Error';
import { useForm } from 'react-hook-form';
import { notification } from 'utils';
import { useStore } from 'hooks';

export const BatchUpload = () => {
  const navigate = useNavigate();
  const { Modal, showModal } = useModal();
  const [errors, setErrors] = useState([]);
  const { control, getValues } = useForm();
  const { user } = useStore();

  const { data } = useQuery({
    queryKey: ['account-balance', user?.organizationId?.accountNumber?.[0]],
    queryFn: () => accountService.getAccountByAccountNo(user?.organizationId?.accountNumber?.[0]),
    enabled: !!user
  });

  const accounts = useMemo(() => {
    return [
      {
        label: (
          <span>
            {user?.organizationId?.accountNumber?.[0]} -{' '}
            <strong>(N{data?.WithdrawableBalance})</strong>
          </span>
        ),
        value: user?.organizationId?.accountNumber?.[0]
      }
    ];
  }, [data, user]);

  const { convertFile, file, clearFile, jsonArray } = useConvertFileToJson();

  const { mutate } = useMutation({
    mutationFn: (data) => transactionService.bulkUploadTransaction(data),
    onSuccess: ({ data }) => {
      const { unresolvedAccount, unresolvedMandates } = data;
      const combinedErrors = [...unresolvedAccount, ...unresolvedMandates];
      if (combinedErrors.length === 0) {
        navigate('/transaction-requests/initiated');
      } else {
        setErrors(combinedErrors);
      }
    },
    onSettled: () => {
      showModal();
    },
    onError: () => {
      showModal();
    }
  });

  const handleUpload = () => {
    const { payerAccountNumber } = getValues();
    if (!payerAccountNumber) {
      notification('Please select a payer account', 'error');
      return;
    }
    showModal();
    clearFile();
    mutate({ file, payerAccountNumber: payerAccountNumber.value });
  };

  return (
    <>
      <div className="px-5 py-8">
        <Container>
          <div className="mb-3">
            <Heading>
              <div className="flex lg:items-center gap-7 md:gap-7 flex-col sm:flex-col md:flex-col lg:flex-row lg:justify-between">
                <div>
                  <h2 className="text-2xl">Batch Transfer</h2>
                  <p className="text-sm mt-2 font-normal">Upload a valid csv/xls file</p>
                </div>

                <div className="flex items-center gap-5">
                  <div className="relative mb-2 text-right">
                    <a
                      href={`${process.env.PUBLIC_URL}/gmfb-cib-bulk-transfer-upload-template.xlsx`}
                      download
                    >
                      <Button
                        buttonText="Generate Template"
                        hidden="hidden"
                        rounded="rounded-md"
                        variant="outline"
                      >
                        Download Template
                      </Button>
                    </a>
                  </div>
                  <div className="relative mb-2 text-right">
                    <a href={`${process.env.PUBLIC_URL}/gmfb-cib-bank-codes.xlsx`} download>
                      <Button
                        buttonText="Generate Template"
                        hidden="hidden"
                        rounded="rounded-md"
                        variant="outline"
                      >
                        Download Bank List
                      </Button>
                    </a>
                  </div>
                </div>
              </div>
            </Heading>
          </div>
          <FileUpload
            accept=".csv, .xlsx, .xls"
            id="file-upload"
            infoText="Upload a CSV or Excel file"
            file={file}
            onChange={(file) => {
              convertFile(file);
              setErrors([]);
            }}
            removeFile={() => {
              clearFile();
            }}
          >
            <div className="mt-4 flex justify-center gap-3 items-end">
              <div className="min-w-[320px]">
                <Select
                  label={<span>Select Originating Account</span>}
                  name="payerAccountNumber"
                  control={control}
                  options={accounts ?? []}
                  error={errors.payerAccountNumber && 'Originating Account number is required'}
                />
              </div>
              <Button onClick={handleUpload}>
                Upload <CloudArrowUpIcon className="w-6 h-6 ml-2" />
              </Button>
            </div>
          </FileUpload>
          {jsonArray.length > 0 && <Preview items={jsonArray} />}
          {errors.length > 0 && <Error items={errors} />}
        </Container>
      </div>
      {Modal({
        children: (
          <div className="flex flex-col items-center">
            <Spinner />
            <SubHeading className="text-center  text-xl font-semibold">
              Processing Requests......
            </SubHeading>
            <p className="mt-5 text-center">
              This may take a few seconds, please {"don't"} close this page.
            </p>
          </div>
        ),
        showCloseIcon: false,
        dismissOnclickOutside: false
      })}
    </>
  );
};
